import _ from 'lodash';
import React, {memo, useCallback, useMemo, useRef} from 'react';
import {Crosshair, MarkSeries, XYPlot} from 'react-vis';

import {
  useInteractStateWhenOnScreen,
  useSetHighlights,
} from '../../state/interactState/hooks';
import {PlotFontSize} from '../../util/plotHelpers/plotFontSize';
import {getPlotMargin} from '../../util/plotHelpers/plotHelpers';
import {Line} from '../../util/plotHelpers/types';
import {TruncationType} from '../common/TruncateText/TruncateTextTypes';
import {usePanelConfigContext} from '../PanelRunsLinePlot/PanelConfigContext';
import {makeClampPoint} from './clampPoint';
import {
  crosshairValuesFromLines,
  getAllCrosshairPointsFromLine,
  MarkSeriesPointForCrosshair,
} from './crosshairUtils';
import Highlight, {BrushEndHandler} from './highlight';
import {CrosshairFlagContent} from './LinePlotCrosshairContent';

type LinePlotCrosshairProps = {
  fontSize: PlotFontSize;
  height: number;
  hideCrosshair: boolean;
  isDrawing: boolean;
  isHovered: boolean;
  lastDrawLocation?: any;
  lines: Line[];
  onBrushEnd: BrushEndHandler;
  onMouseDown: React.MouseEventHandler;
  onMouseUp: React.MouseEventHandler;
  pointClamper: ReturnType<typeof makeClampPoint>;
  runNameTruncationType?: TruncationType;
  setIsDrawing: (isDrawing: boolean) => void;
  singleRun: boolean;
  width: number;
  xAxis: string;
  xDomain: number[];
  xScale: 'linear' | 'log';
  yAxis: string;
  yDomain: number[];
  yScale: 'linear' | 'log';
};

const hardCodedTransform = {willChange: 'transform'};
const hardCodedMarkSeries = {
  fill: '#fff',
  strokeWidth: 2,
};

const LinePlotCrosshair = ({
  fontSize,
  height,
  hideCrosshair,
  isDrawing,
  isHovered,
  lines,
  onBrushEnd,
  onMouseDown,
  onMouseUp,
  pointClamper,
  runNameTruncationType,
  setIsDrawing,
  singleRun,
  width,
  xAxis,
  xDomain,
  xScale,
  yAxis,
  yDomain,
  yScale,
}: LinePlotCrosshairProps) => {
  const yAxisTickTotal = yScale === 'log' ? 2 : 5;
  const crosshairFlagRef = useRef<HTMLDivElement>(null);

  const {displayFullRunName} = usePanelConfigContext();

  // maximum characters we want to show in the crosshair box for hovered
  // and non-hovered tooltips
  const CROSSHAIR_MAX_LENGTH = 50;
  const CROSSHAIR_MIN_LENGTH = 8;
  const CROSSHAIR_MAX_LENGTH_NOT_HOVERED = 24;
  const getMaxStringLength = (panelWidth: number) => {
    const maxBound = CROSSHAIR_MAX_LENGTH;
    const minBound = CROSSHAIR_MIN_LENGTH;
    const maxWidth = 650; // Width where maxBound applies
    const minWidth = 300; // Width where minBound applies

    // Clamp the screen width to be within the min and max widths
    const clampedWidth = Math.max(minWidth, Math.min(panelWidth, maxWidth));
    const widthRatio = (clampedWidth - minWidth) / (maxWidth - minWidth);
    return Math.round(minBound + widthRatio * (maxBound - minBound));
  };

  const maxCharacterLength = useMemo(() => getMaxStringLength(width), [width]);

  const [domRef, [highlightX, highlightRun]] = useInteractStateWhenOnScreen(
    interactState => [
      interactState.highlight[xAxis] as number | undefined,
      interactState.highlight['run:name'] as string,
    ]
  );

  const setHighlights = useSetHighlights();

  const enabledLines = useMemo(() => lines.filter(({aux}) => !aux), [lines]);
  const {highlights, crosshairCount} = useMemo(
    function createCrosshairHighlights() {
      // False line is a straight line that fits within the chart data. We render
      // it transparently and just use it for it's onNearestX callback.
      // BUT IT NO LONGER EXISTS, HA
      // so this code may be out of place now--consider refactoring
      if (enabledLines.length > 0) {
        if (enabledLines[0].type === 'heatmap') {
          // pre-compute highlights for histogram
          const hls: any = {};
          enabledLines[0].data.forEach(row => {
            hls[row.x] = hls[row.x] || [
              {
                x: row.x,
                title: 'histogram',
                color: enabledLines[0].color,
                values: [],
              },
            ];
            hls[row.x][0].values.push(row.color);
          });
          return {
            highlights: hls,
            crosshairCount: Object.keys(hls).length,
          };
        } else {
          return {
            highlights: null,
            crosshairCount: Math.max(
              ...enabledLines.map(line => line.data.length)
            ),
          };
        }
      }
      return {highlights: null, crosshairCount: 0};
    },
    [enabledLines]
  );

  const enabledLinesWithCrosshairPoints = useMemo(() => {
    return enabledLines.map(line => ({
      line,
      crosshairPoints: getAllCrosshairPointsFromLine(line),
    }));
  }, [enabledLines]);

  const crosshairValues: MarkSeriesPointForCrosshair[] | null = useMemo(
    function calculateCrosshairValues() {
      if (highlightX == null) {
        return null;
      }

      if (highlights == null) {
        return crosshairValuesFromLines(
          enabledLinesWithCrosshairPoints,
          highlightX,
          xDomain[0],
          xDomain[1]
        );
      }

      const xVals = Object.keys(highlights).map(s => parseInt(s, 10));
      if (xVals.length === 0) {
        return null;
      }

      const closest = xVals.reduce((prev, curr) =>
        Math.abs(curr - highlightX) < Math.abs(prev - highlightX) ? curr : prev
      );

      return highlights[closest];
    },
    [enabledLinesWithCrosshairPoints, highlightX, highlights, xDomain]
  );

  const showMarkSeries =
    !hideCrosshair &&
    crosshairValues != null &&
    crosshairValues.length > 0 &&
    crosshairValues[0].values == null;

  const handleMousMoveWithXY = useCallback(
    (mouseX: number, mouseY: number) => {
      // console.time('onMouseMoveWithXY');
      const newHighlights: Array<{
        axis: string;
        value: string | number | undefined;
      }> = [{axis: xAxis, value: mouseX}];

      // Compute the next crosshair values now, and then find the
      // the line whose crosshair point y-value is nearest to
      // mouseY. This is a little strange but it mostly works.
      const newCrosshairValues = crosshairValuesFromLines(
        enabledLinesWithCrosshairPoints,
        mouseX,
        xDomain[0],
        xDomain[1]
      ).sort((a, b) => (a.y as number) - (b.y as number));
      if (newCrosshairValues.length > 0) {
        let lineIndex = _.sortedIndexBy(
          newCrosshairValues,
          {y: mouseY} as any,
          pt => pt.y
        );
        if (lineIndex === newCrosshairValues.length) {
          lineIndex = lineIndex - 1;
        } else if (lineIndex !== 0 && newCrosshairValues.length > 1) {
          lineIndex =
            newCrosshairValues[lineIndex].y - mouseY <
            mouseY - newCrosshairValues[lineIndex - 1].y
              ? lineIndex
              : lineIndex - 1;
        }
        newHighlights.push({
          axis: 'run:name',
          value: newCrosshairValues[lineIndex].uniqueId,
        });
        // setHighlightRun(newCrosshairValues[lineIndex].title || null);
      }
      setHighlights(newHighlights);
      // console.timeEnd();
    },
    [enabledLinesWithCrosshairPoints, setHighlights, xAxis, xDomain]
  );

  const handleMouseLeave = useCallback(() => {
    setHighlights([
      {axis: xAxis, value: undefined},
      {axis: 'run:name', value: undefined},
    ]);
    // setHighlightRun(null);
  }, [setHighlights, xAxis]);

  const markSeriesValues = useMemo(
    function markSeriesValuesMemo() {
      return crosshairValues
        ?.filter(v => {
          return v.name === highlightRun;
        })
        .map(p => pointClamper(p));
    },
    [crosshairValues, highlightRun, pointClamper]
  );

  const reducedCrosshairValues =
    highlightX != null && crosshairValues && crosshairValues.length > 0
      ? [
          crosshairValues.reduce((closest, pt) =>
            Math.abs((pt.x as number) - highlightX!) <
            Math.abs((closest.x as number) - highlightX!)
              ? pt
              : closest
          ),
        ]
      : undefined;

  const plotMargin = useMemo(
    function plotMarginMemo() {
      return getPlotMargin({
        axisKeys: {xAxis, yAxis},
        axisDomain: {
          yAxis: yDomain,
        },
        axisType: {yAxis: yScale},
        tickTotal: {yAxis: yAxisTickTotal},
        fontSize,
      });
    },
    [fontSize, xAxis, yAxis, yAxisTickTotal, yDomain, yScale]
  );

  return (
    <div
      data-id="crosshair-wrapper"
      key="line-plot-crosshair-wrapper"
      ref={domRef}>
      <XYPlot
        animation={false}
        dontCheckIfEmpty
        height={height}
        key="line-plot-crosshair"
        margin={plotMargin}
        onMouseDown={onMouseDown}
        onMouseLeave={handleMouseLeave}
        // willChange: transform forces the crosshair into it's own rendering layer which means the plot behind won't be re-rendered when the crosshair moves. This improved FPS from 2 to >60 on large plots.
        style={hardCodedTransform}
        width={width}
        xDomain={xDomain}
        xType={xScale}
        yDomain={yDomain}
        yType={yScale}>
        <Highlight
          key="lineplot-crosshair-highlight"
          stepCount={crosshairCount}
          onMouseUp={onMouseUp}
          onBrushEnd={onBrushEnd}
          onMouseMoveWithXY={handleMousMoveWithXY}
          isDrawing={isDrawing}
          setIsDrawing={setIsDrawing}
        />

        {showMarkSeries && (
          <MarkSeries
            colorType="literal"
            data={markSeriesValues}
            key="cross-hair-literal-mark-series"
            size={4}
            style={hardCodedMarkSeries}
          />
        )}
        {!hideCrosshair && crosshairValues && crosshairValues.length > 0 && (
          <Crosshair values={reducedCrosshairValues}>
            {/* crosshairFlagContent is duplicated as part of a hack to
              prevent the flag from getting cut off by the panel boundaries
              the div with name line-plot-flag-escaping has display: none overridden elsewhere
              only for the highlighted panel. For the other panels this div doesn't appear. */}

            <div className="line-plot-flag" ref={crosshairFlagRef}>
              <CrosshairFlagContent
                allowCopyContents={
                  false /* don't want the truncated copy to generate data*/
                }
                crosshairValues={crosshairValues}
                highlightRun={singleRun ? undefined : highlightRun}
                isHovered={isHovered}
                isSparkLines={
                  enabledLines.length > 0 && enabledLines[0].type === 'heatmap'
                }
                maxLength={
                  isHovered
                    ? maxCharacterLength
                    : Math.min(
                        CROSSHAIR_MAX_LENGTH_NOT_HOVERED,
                        maxCharacterLength
                      )
                }
                truncateLegend={displayFullRunName && isHovered ? false : true}
                runNameTruncationType={runNameTruncationType}
                xAxis={xAxis}
              />
            </div>
            {isHovered && (
              <div
                className="line-plot-flag-escaping"
                data-test="active-flag-content"
                style={{
                  display: 'none',
                  position: 'fixed',
                  zIndex: 1000,
                  marginTop: crosshairFlagRef.current
                    ? -crosshairFlagRef.current.clientHeight
                    : 0,
                }}>
                <CrosshairFlagContent
                  allowCopyContents={isHovered}
                  crosshairValues={crosshairValues}
                  highlightRun={singleRun ? undefined : highlightRun}
                  isHovered={isHovered}
                  isSparkLines={
                    enabledLines.length > 0 &&
                    enabledLines[0].type === 'heatmap'
                  }
                  maxLength={maxCharacterLength}
                  truncateLegend={
                    displayFullRunName && isHovered ? false : true
                  }
                  runNameTruncationType={runNameTruncationType}
                  xAxis={xAxis}
                />
              </div>
            )}
          </Crosshair>
        )}
      </XYPlot>
    </div>
  );
};

export default memo(LinePlotCrosshair);
