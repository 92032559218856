import {useLocation} from 'react-router';

import {getBooleanValueFromQueryString} from '../query-string';
import {
  useRampFlagGQLLongTermCacheByEntity,
  useRampFlagGQLLongTermCacheByUser,
} from '../rampFeatureFlags';
import {getCache} from './getCache';
import {LongTermCache} from './types';

const cacheDisabledFromQueryParams =
  getBooleanValueFromQueryString('DISABLE_CACHE');

function useEntityNameFromPath(): string {
  const location = useLocation();
  return location.pathname.split('/')[1];
}

/**
 * Hook that determines if caching should be enabled based on URL params and feature flags.
 *
 * Checks for DISABLE_CACHE query parameter and ramp feature flags to determine if caching
 * should be enabled for the current user/entity.
 *
 * @returns {boolean} True if caching should be enabled, false otherwise
 */
export function useCacheEnabled(isFlagEnabled?: boolean): boolean {
  const location = useEntityNameFromPath();
  // When isFlagEnabled is undefined, these will be ignored and assumed to be true
  const gqlLongTermCacheByUser = useRampFlagGQLLongTermCacheByUser();
  const gqlLongTermCacheByEntity =
    useRampFlagGQLLongTermCacheByEntity(location);

  if (cacheDisabledFromQueryParams) {
    return false;
  }

  // By default, skip ramp flag checks and assume cache is enabled
  if (isFlagEnabled === undefined) {
    return true;
  }

  // Only check ramp flags if explicitly requested
  const cacheEnabled =
    isFlagEnabled && (gqlLongTermCacheByUser || gqlLongTermCacheByEntity);
  return cacheEnabled;
}

export function useCacheIfEnabled(
  isFlagEnabled?: boolean
): LongTermCache | null {
  const cache = getCache();
  const isEnabled = useCacheEnabled(isFlagEnabled);
  if (!isEnabled) {
    return null;
  }
  return cache;
}
