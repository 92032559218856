import {TooltipDeprecated} from '@wandb/weave/components/TooltipDeprecated';
import React, {FC, memo, useRef, useState} from 'react';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {
  useNavContext,
  useNavUpdaterContext,
} from '../../../components/NavContextProvider';
import {urlPrefixed} from '../../../config';
import {HomePageSidebar} from '../../../pages/HomePage/HomePageSidebar';
import {isInIframe} from '../../../setup';
import {FloatingSidebar} from '../../PageWithSidebar/FloatingSidebar';
import WBLogo from '../../WBLogo';
import NavLeftDefault from './NavLeftDefault';
import NavLeftInIframe from './NavLeftInIframe';

const NavLeftComp: FC = () => {
  const globalNavTriggerRef = useRef<HTMLButtonElement>(null);
  const {globalNav, isCreatingProject, isCreatingTeam} = useNavContext();
  const {updateGlobalNav} = useNavUpdaterContext();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipTimeout, setTooltipTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const openTooltipWithDelay = () => {
    const timeout = setTimeout(() => setIsTooltipOpen(true), 500);
    setTooltipTimeout(timeout);
  };

  const closeTooltip = () => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
      setTooltipTimeout(null);
    }
    setIsTooltipOpen(false);
  };

  return (
    <div className="nav-left-container">
      <TooltipDeprecated
        position="bottom center"
        open={isTooltipOpen}
        trigger={
          <WBLogo
            data-test="wb-logo"
            aria-label={`${
              globalNav.isOpen ? 'Close' : 'Open'
            } global navigation`}
            ref={globalNavTriggerRef}
            onClick={e => {
              window.analytics?.track('Nav logo clicked');
              closeTooltip();
              if (isInIframe()) {
                e.preventDefault();
                window.open(urlPrefixed());
              } else {
                updateGlobalNav({
                  isOpen: !globalNav.isOpen,
                  shouldAnimate: true,
                });
              }
            }}
            onMouseEnter={() => {
              updateGlobalNav({isTriggerHovered: true});
              openTooltipWithDelay();
            }}
            onMouseLeave={() => {
              updateGlobalNav({isTriggerHovered: false});
              closeTooltip();
            }}
          />
        }>
        {globalNav.isOpen ? 'Close' : 'Open'} global navigation
      </TooltipDeprecated>
      {!globalNav.isInline && (
        <FloatingSidebar
          isOpen={globalNav.isOpen}
          triggerRef={globalNavTriggerRef}
          close={() => updateGlobalNav({isOpen: false, shouldAnimate: true})}
          shouldAnimate={globalNav.shouldAnimate}
          isCreatingTeamOrProject={isCreatingTeam || isCreatingProject}>
          <HomePageSidebar />
        </FloatingSidebar>
      )}
      {isInIframe() ? <NavLeftInIframe /> : <NavLeftDefault />}
    </div>
  );
};

const NavLeft = memo(NavLeftComp);

export default NavLeft;
