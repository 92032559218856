import {useEffect, useState} from 'react';

import {backendHost} from '../../../../src/config';

export const calculateTtl = (timestamp: string): string => {
  const currentTime = new Date().getTime();
  const roleTime = new Date(timestamp).getTime();

  const ttl = roleTime - currentTime;

  if (ttl <= 0) {
    return '';
  }

  const hours = Math.floor(ttl / (1000 * 60 * 60));
  const minutes = Math.floor((ttl % (1000 * 60 * 60)) / (1000 * 60));

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  }
  return `${minutes}m`;
};

export function useTeleportAccessRequests() {
  const [approvedTeleportAccessRequests, setApprovedTeleportAccessRequests] =
    useState<Record<string, string>>({});
  const [
    isFetchingApprovedTeleportAccessRequests,
    setIsFetchingApprovedTeleportAccessRequests,
  ] = useState(true);

  useEffect(() => {
    const fetchApprovedTeleportAccessRequests = async () => {
      try {
        const response = await fetch(backendHost(`/teleport/access_requests`), {
          method: 'GET',
          credentials: 'include',
        });
        const data = await response.json();
        setApprovedTeleportAccessRequests(data);
      } catch (error) {
        setApprovedTeleportAccessRequests({});
      } finally {
        setIsFetchingApprovedTeleportAccessRequests(false);
      }
    };
    fetchApprovedTeleportAccessRequests();
  }, []);

  return {
    approvedTeleportAccessRequests,
    isFetchingApprovedTeleportAccessRequests,
  };
}
