export type PointPosition = {
  isFirstPoint: boolean;
  isLastPoint: boolean;
};
import {
  BIN_POINT_TO_USE,
  type ChartAggOption,
} from './../../../util/plotHelpers/chartTypes';
import {
  HistoryRecord,
  SafeAreaPoint,
  SafePoint,
  ValidHistoryKey,
} from './types';
import {
  getAreaSafeYKeys,
  getSafeXKey,
  getSafeYKey,
  XKeyPositions,
} from './util';

export type PointWithMeta =
  | (Partial<HistoryRecord> & SafePoint)
  | (Partial<HistoryRecord> & SafeAreaPoint);

export function buildPoint(
  historyPoint: HistoryRecord,
  xMetric: string,
  yMetric: string,
  groupAgg: ChartAggOption | undefined
): SafePoint {
  // we need to return the min or max x-value to plot the ends of the graph
  const xKey = getSafeXKey(
    xMetric,
    yMetric,
    BIN_POINT_TO_USE.toLowerCase() as XKeyPositions
  );
  const yKey = getSafeYKey(yMetric, undefined, groupAgg);

  return {
    x: Number(historyPoint[xKey]),
    y: Number(historyPoint[yKey]),
  };
}

// mutation function which extends a point with any other metric values
// required to compute expressions later on
export function extendPoint(
  point: PointWithMeta,
  historyPoint: HistoryRecord,
  metrics: string[],
  pointType: ChartAggOption | undefined
) {
  metrics.forEach(metric => {
    const yKey = getSafeYKey(metric, undefined, pointType);
    if (yKey in historyPoint) {
      point[metric as ValidHistoryKey] = Number(historyPoint[yKey]);
    }
  });
}

export function buildNonFinitePoint(
  historyPoint: HistoryRecord,
  xMetric: string,
  yMetric: string,
  _: ChartAggOption | undefined,
  isNanPoint: boolean
): PointWithMeta {
  // we need to return the min or max x-value to plot the ends of the graph
  const xKey = getSafeXKey(
    xMetric,
    yMetric,
    BIN_POINT_TO_USE.toLowerCase() as XKeyPositions
  );
  const yKey = getSafeYKey(yMetric);

  return {
    x: Number(historyPoint[xKey]),
    // go ahead and convert string values of NaN/Inf/-Inf to numbers
    // for cleaner typing downstream
    y: isNanPoint ? NaN : Number(historyPoint[yKey]),
  };
}

/**
 * Area points are complicated:
 * If the area point is a bin of size > 1, we need to construct non-finite points to render on the chart while ALSO rendering the ranges. If the bucket size is 1 then the non-finite point rendering will be handled by the primary line (which will have the non-finite value as its `avg` - as Inf/-Inf/NaN will not show up directly while aggregating multiple points into a single point)
 * @returns
 */
export function buildAreaPoint(
  historyPoint: HistoryRecord,
  xMetric: string,
  yMetric: string
): [PointWithMeta, PointWithMeta | undefined, PointWithMeta | undefined] {
  // we need to return the min or max x-value to plot the ends of the graph
  const xKey = getSafeXKey(xMetric, yMetric);
  const {yKey, y0Key} = getAreaSafeYKeys(yMetric);

  const v = {
    x: Number(historyPoint[xKey]),
    // go ahead and convert string values of NaN/Inf/-Inf to numbers
    // for cleaner typing downstream
    y: Number(historyPoint[yKey]),
    y0: Number(historyPoint[y0Key]),
  };

  const isBin = historyPoint.bucketSize > 1;

  /**
   * We need to return NaN points if the min or max value are non-finite
   * We can safely return y0 points as Infinity and y points as -Infinity as if there was a bin of points where all the values where Inf/-Inf (such that the min and the max were the same value) then we would only render that pair of points as a single point (no double rendering of overlapping min/max points)
   */
  const max =
    isBin && v.y0 === Infinity
      ? {
          x: v.x,
          y: v.y0,
        }
      : undefined;

  const min =
    isBin && v.y === -Infinity
      ? {
          x: v.x,
          y: v.y,
        }
      : undefined;

  if (isNaN(v.y) || isNaN(v.y0)) {
    throw new Error(`Encountered a NaN value as a min or max`);
  }

  if (v.y > v.y0) {
    throw new Error(
      `Encountered a max value lesser than min, ${JSON.stringify(v)}`
    );
  }

  // we filter these off at the processing site
  return [v, min, max];
}

export function buildTimePoint(
  historyPoint: HistoryRecord,
  time: {
    value: number;
    unit: 'seconds' | 'milliseconds';
  }
) {
  const suffixes = ['Min', 'Avg', 'Max', 'Last'] as const;
  const startTime = time.unit === 'seconds' ? time.value : time.value / 1000;
  suffixes.forEach(suffix => {
    if (typeof historyPoint[`_timestamp${suffix}`] === 'number') {
      historyPoint[`_absolute_runtime${suffix}`] =
        (historyPoint[`_timestamp${suffix}`] as number) - startTime;
    }
  });
}
