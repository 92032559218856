import {useDeepMemo} from '@wandb/weave/hookUtils';
import {useEffect, useMemo, useReducer} from 'react';

import {Query} from '../../../util/queryTypes';
import {useSharedPanelState} from '../../Panel/SharedPanelStateContext';
import {POINT_VISUALIZATION_OPTIONS} from '../../WorkspaceDrawer/Settings/types';
import {Range, runsLinePlotTransformQuery} from '../common';
import {usePanelConfigContext} from '../PanelConfigContext';
import {RunsLinePlotConfig} from '../types';
import {getRunSets} from '../utils/getRunSets';
import {useDeepEqualValue} from './../../../util/hooks';
import {bucketedRunsReducer} from './bucketedDataReducer';
import {BucketedQueryState} from './bucketedQueryManager';
import {bracketHistoriesData, mapEnabledByRunSets} from './util';

const usePanelQuery = (
  pageQuery: Query,
  config: RunsLinePlotConfig,
  queryZoomRange: Range,
  skip?: boolean
) => {
  const {limit: numRuns, parsedExpressions} = usePanelConfigContext();
  const transformed = skip
    ? null
    : runsLinePlotTransformQuery({
        query: pageQuery,
        runsLinePlotConfig: config,
        xStepRange: queryZoomRange,
        parsedExpressions,
        defaultMaxRuns: numRuns,
        isFullFidelity: true,
      });
  return useDeepMemo(transformed);
};

function useRunSets(pageQuery: Query) {
  const runSets = useMemo(() => getRunSets(pageQuery), [pageQuery]);
  const runSetsById = mapEnabledByRunSets(runSets);
  const deepEqualEnabledRunSets = useDeepEqualValue(runSetsById);

  return deepEqualEnabledRunSets;
}

export function useBucketedData(
  config: RunsLinePlotConfig,
  pageQuery: Query,
  queryZoomRange: Range,
  nBuckets: number,
  skip?: boolean
) {
  const panelQuery = usePanelQuery(pageQuery, config, queryZoomRange, skip);
  const {bucketQueryManagerById} = useSharedPanelState();

  const runSets = useRunSets(pageQuery);

  const [bucketedRunsState, bucketedRunsDispatch] = useReducer(
    bucketedRunsReducer,
    {
      runSetsById: runSets,
      runDataById: {},
    }
  );

  // TODO: it seems like this is firing even though runSets should be stable. Not a big deal because the reducer
  // handles the trash, but need to figure this out.
  useEffect(() => {
    bucketedRunsDispatch({
      type: 'bucketedRuns/setRunSetEnabled',
      payload: runSets,
    });
  }, [runSets]);

  useEffect(() => {
    if (!panelQuery) {
      return;
    }
    const managers = bucketQueryManagerById.current;
    const handlers: ((state: BucketedQueryState) => void)[] = [];

    panelQuery.queries.forEach(q => {
      const handlerDataChange = (state: BucketedQueryState) => {
        const bracketedState = bracketHistoriesData(state, config.xAxis);
        bucketedRunsDispatch({
          type: 'bucketedRuns/setRunDataById',
          id: q.id,
          data: bracketedState,
        });
      };

      handlers.push(handlerDataChange);

      bucketQueryManagerById.current[q.id].registerRequest({
        handler: handlerDataChange,
        nBuckets,
        runsDataQuery: panelQuery,
        singleQuery: q,
      });
    });

    // Cleanup function that unregisters all handlers
    return () => {
      panelQuery.queries.forEach((q, index) => {
        if (managers[q.id]) {
          managers[q.id].unregisterRequest(handlers[index]);
        }
      });
    };
  }, [nBuckets, panelQuery, bucketQueryManagerById, config.xAxis]);

  const bucketedDataMemo = useMemo(() => {
    return {
      _dataType: POINT_VISUALIZATION_OPTIONS.BucketingGorilla,
      entityName: pageQuery.entityName,
      histories: {
        data: Object.keys(bucketedRunsState.runDataById)
          .filter(key => bucketedRunsState.runSetsById[key] ?? false)
          .flatMap(key => {
            const data = bucketedRunsState.runDataById[key];
            try {
              const result = Object.values(data.data?.runsById ?? {}).filter(
                r => !!r
              );
              return result;
            } catch (e) {
              console.error(e);
              return [];
            }
          }),
      },
      initialLoading: false,
      loadMore: () => {},
      projectName: pageQuery.projectName,
    };
  }, [pageQuery.entityName, pageQuery.projectName, bucketedRunsState]);

  const {error, loading} = useMemo(() => {
    return {
      error: Object.values(bucketedRunsState.runDataById).find(v => v.error),
      loading: Object.values(bucketedRunsState.runDataById).some(
        v => v.loading
      ),
    };
  }, [bucketedRunsState.runDataById]);

  if (error) {
    console.error(error);
  }

  return useMemo(
    () => ({
      data: bucketedDataMemo,
      error,
      loading,
    }),
    [bucketedDataMemo, error, loading]
  );
}
