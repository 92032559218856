import {MatchParams} from '@wandb/weave/common/types/base';
import {
  ImageMetadata,
  MediaCardMetadata,
} from '@wandb/weave/common/types/media';
import {linkify} from '@wandb/weave/common/util/links';
import {Struct} from '@wandb/weave/common/util/types';
import _ from 'lodash';
import React, {
  MutableRefObject,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Dropdown} from 'semantic-ui-react';

import type {
  MediaCardProps,
  RunWithHistoryAndMediaWandb,
} from '../components/MediaCard';
import type {MediaBrowserPanelConfig} from '../components/PanelMediaBrowser';
import {backendHost} from '../config';
import {RunHistoryRow, RunWithHistory} from '../types/run';
import {colorNRGB, ROBIN16} from './colors';
import {WANDB_DELIMETER, ZOOM_MIGRATED_TOKEN} from './mediaConstants';

export * from '@wandb/weave/common/util/media';

export interface WBFile {
  path: string;
  _type: string;
  sha256: string;
  size: 144;
}

export function getLastHistoryRowWithKey(
  run: RunWithHistory,
  key: string,
  stepCeiling?: number
): RunHistoryRow | null {
  if (run.history == null) {
    return null;
  }

  for (let i = run.history.length - 1; i >= 0; i--) {
    const historyRow = run.history[i];
    if (stepCeiling != null && historyRow._step > stepCeiling) {
      continue;
    }
    if (historyRow[key] != null) {
      return historyRow;
    }
  }

  return null;
}

// Returns all steps from a run where the give key is present
export function stepsWithKey(run: RunWithHistory, key: string): number[] {
  return (
    run.history
      ?.filter(historyRow => key in historyRow)
      .map(historyRow => historyRow._step) ?? []
  );
}

// Returns all steps from a run where one of a given key is present
export function runHistoryRowsWithOneOfKeys(
  run: RunWithHistory,
  keys?: string[]
): RunHistoryRow[] {
  if (
    keys == null ||
    keys.length === 0 ||
    run.history == null ||
    run.history.length === 0
  ) {
    return [];
  }
  return run.history.filter(row => {
    return keys.some(key => key in row);
  });
}

export function latestStepWithMedia(
  stepsWithMedia: number[],
  stepCeiling: number
): number | null {
  for (let i = stepsWithMedia.length - 1; i >= 0; i--) {
    const step = stepsWithMedia[i];
    if (step <= stepCeiling) {
      return step;
    }
  }
  return null;
}

export function runFileSource(matchParams: MatchParams, path: string): string {
  const {entityName, projectName, runName} = matchParams;
  const fullPath = `${backendHost()}/files/${entityName}/${projectName}/${runName}`;

  return encodeURI(`${fullPath}/${path}`);
}

// returns an encoded url for a media src file
export const mediaSrc = (
  matchParams: MatchParams,
  fileParams: Array<string | number>, // [mediaKey, stepIndex] for images or [mediaKey, stepIndex, mediaIndex] for audio
  mediaType: 'images' | 'audio' | 'html' | 'videos',
  extension?: string
) => {
  const {entityName, projectName, runName} = matchParams;
  const fullPath = `${backendHost()}/files/${entityName}/${projectName}/${runName}`;
  const fileName = mediaFilePath(fileParams, mediaType, extension);
  return encodeURI(`${fullPath}/${fileName}`);
};

type MediaType = 'images' | 'audio' | 'html' | 'object3D' | 'videos';

// returns an encoded filename for a media src file (includes the 'media/${mediaType}/' prefix)
export function mediaFilePath(
  fileParams: Array<string | number>,
  mediaType: MediaType,
  extension?: string
): string {
  const filename = fileParams.join('_');
  let fileType: string;
  if (extension) {
    fileType = extension;
  } else if (mediaType === 'images') {
    fileType = 'jpg';
  } else if (mediaType === 'audio') {
    fileType = 'wav';
  } else if (mediaType === 'videos') {
    fileType = 'gif';
  } else {
    fileType = 'html';
  } // we currently only support images, audio, and html
  return `media/${mediaType}/${filename}.${fileType}`;
}

export const filePath = (filename: string, mediaType: MediaType) => {
  return encodeURI(`media/${mediaType}/${filename}`);
};

/* === mediaType: IMAGES === */

/* === AUDIO === */

// returns the 'durations' array for the given run/step/key
export const getAudioDurations = (
  run: any,
  stepIndex: number,
  mediaKey: string
) => {
  return [];
};

// returns the max audio duration for the given parameters.
// note: this behaves differently if allRuns.length === 1 (e.g. on run page)
// if we have one run, we want to compare durations across all mediaIndexes for that run+step+key
// but if we have multiple runs (allRuns.length > 1, e.g. on report page), we want to compare durations for a single mediaIndex across runs
export const getMaxAudioDuration = (
  allRuns: RunWithHistory[],
  stepIndex: number,
  mediaKey: string,
  mediaIndex: number
): number =>
  _.max(
    allRuns.map(r => {
      const allDurations = getAudioDurations(r, stepIndex, mediaKey);
      return allRuns.length === 1
        ? _.max(allDurations) // run page
        : allDurations[mediaIndex]; // report page
    })
  ) || 0;

// returns the width of the current waveform in percent (not px!)
// calculated by comparing its duration vs the duration of all currently-displayed waveforms
export const scaledWaveformWidth = (
  allRuns: RunWithHistory[],
  runIndex: number,
  stepIndex: number,
  mediaKey: string,
  mediaIndex: number
) => {
  const maxAudioDuration: number = getMaxAudioDuration(
    allRuns,
    stepIndex,
    mediaKey,
    mediaIndex
  );
  const currentAudioDuration = getAudioDurations(
    allRuns[runIndex],
    stepIndex,
    mediaKey
  )[mediaIndex];

  return 100 * ((currentAudioDuration || 0) / maxAudioDuration);
};

// Helper components for media cards

export const MediaStepDropdown = React.memo(
  ({
    step,
    globalStep,
    stepDropdownOptions,
    setStep,
  }: {
    step: number | undefined;
    globalStep: number;
    stepDropdownOptions: any;
    setStep: (n: number) => void;
  }) => {
    if (_.isEmpty(stepDropdownOptions)) {
      return null;
    }

    const stepMismatch =
      !_.isUndefined(globalStep) && step !== globalStep ? 'step-mismatch' : '';

    return (
      <Dropdown
        key={step || 'none'}
        floating
        lazyLoad
        className={'panel-media-step ' + stepMismatch}
        scrolling
        compact
        value={step}
        options={stepDropdownOptions}
        onChange={(e, {value}) => {
          setStep(value as number);
        }}
      />
    );
  }
);

type CaptionMetadata = {
  caption?: any;
  captions?: any[];
  grouping?: number;
};

export function makeCaptions(
  metadata: CaptionMetadata | null | undefined,
  mediaIndex: number
): React.ReactNode[] {
  if (metadata == null) {
    return [];
  }

  const rawCaptions = getRawCaptionsFromMetadata(metadata, mediaIndex);

  return rawCaptions.map((caption, i) => (
    <div key={i} style={{flexGrow: 1}}>
      <div className="image-card-caption-text">
        {caption.split('\n').map((line, j) => (
          <div key={j}>
            {linkify(line, {onClick: e => e.stopPropagation()})}
          </div>
        ))}
      </div>
    </div>
  ));
}

function getRawCaptionsFromMetadata(
  metadata: CaptionMetadata,
  mediaIndex: number
): string[] {
  if (metadata.caption) {
    return [String(metadata.caption)];
  }
  if (metadata.captions) {
    // Some captions are a combination of a sequence of images
    // from cli/data_types: seq_to_json
    const groupCount = metadata?.grouping || 1;
    return metadata.captions
      .slice(mediaIndex * groupCount, (mediaIndex + 1) * groupCount)
      .map(c => String(c));
  }
  return [];
}

export function metadataHasCaptions(metadata: Struct | null): boolean {
  if (metadata == null) {
    return false;
  }
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!metadata.caption) {
    return true;
  }
  if (metadata.captions != null && metadata.captions.length > 0) {
    return true;
  }
  return false;
}

export const labelComponent = (
  props: MediaCardProps,
  currentStep: number | undefined,
  titleLink: JSX.Element
) => {
  return (
    props.labels &&
    props.labels.map(l => {
      return (
        <div key={l} style={{fontSize: '10px', lineHeight: '16px'}}>
          {l === 'step' ? (
            <span>Step {currentStep}</span>
          ) : l === 'index' ? (
            <span>Index {props.mediaIndex}</span>
          ) : l === 'run' ? (
            <span className="text__single-line" style={{display: 'block'}}>
              {props.disableRunLink ? props.run.displayName : titleLink}
            </span>
          ) : (
            'None'
          )}
        </div>
      );
    })
  );
};

// ** Migrations **

type DeprecatedConfigValues = DeprecatedZoom & DeprecatedLayoutConfig;
export const runMediaPanelMigrations = (
  config: MediaBrowserPanelConfig & DeprecatedConfigValues
): MediaBrowserPanelConfig => {
  return convertMediaPanelConfigToLayoutV2(
    convertMediaPanelConfigZoomToGallery(config)
  );
};
//
// This migration uses heuristics to set old views into a close approximation of their old image sizes,
// but in their new gallery layout
//
// Zoom to Columns Migration
interface DeprecatedZoom {
  zoom: number;
}

export const convertMediaPanelConfigZoomToGallery = (
  config: MediaBrowserPanelConfig & DeprecatedZoom
): MediaBrowserPanelConfig => {
  const newConfig = _.clone(config);

  if (config.zoom != null && (config as any).zoom !== ZOOM_MIGRATED_TOKEN) {
    const zoom = config.zoom;

    // Migrate all non zoom panels to a simple 3 column actualSize
    if (zoom === 1) {
      newConfig.actualSize = true;
      newConfig.columnCount = 3;
      // Migrate any other zooms to a best guess of their columnCount
    } else if (zoom > 2.5) {
      newConfig.columnCount = 1;
    } else if (zoom <= 2.5) {
      newConfig.columnCount = 2;
    } else if (zoom < 1.4) {
      newConfig.columnCount = 3;
    } else if (zoom < 0.3) {
      newConfig.columnCount = 5;
    } else if (zoom < 0.25) {
      newConfig.columnCount = 8;
    }
    (newConfig as any).zoom = ZOOM_MIGRATED_TOKEN;
  }

  return newConfig;
};

export function getMediaMetadata(
  history: RunHistoryRow[] | null,
  key: string,
  currentStep: number
): MediaCardMetadata | null {
  if (history == null) {
    return null;
  }

  // first, try to find the current step
  const currentStepHistoryWithKey = _.find(
    history,
    hr => hr._step === currentStep && key in hr
  );

  if (currentStepHistoryWithKey != null) {
    return currentStepHistoryWithKey[key];
  }

  // if we were unable to find the current step or the current step does
  // not contain the desired key, take the first step with the desired key
  const historyWithKey = _.find(history, hr => key in hr);
  return historyWithKey?.[key] ?? null;
}

export function getMasks(
  mediaMetadata: ImageMetadata,
  mediaIndex: number
): Struct<WBFile> {
  if (mediaMetadata.masks != null) {
    return mediaMetadata.masks;
  }

  if (mediaMetadata.all_masks?.[mediaIndex] != null) {
    return mediaMetadata.all_masks[mediaIndex];
  }

  return {};
}

export function getBoundingBoxes(
  mediaMetadata: ImageMetadata,
  mediaIndex: number
): Struct<WBFile> {
  if (mediaMetadata.boxes != null) {
    return mediaMetadata.boxes;
  }

  if (mediaMetadata.all_boxes?.[mediaIndex] != null) {
    const v = mediaMetadata.all_boxes[mediaIndex];
    // The original box API was un-nested and returned path at the top level.
    // Only a few runs were logged using this legacy API by wandb test users
    // so we don't want to support this anymore.
    // Return an empty object to ignore these boxes and pretend as if there are no boxes.
    if (v.path != null) {
      return {};
    }
    return v;
  }

  return {};
}

// Layout V2 Migration:
//
// Introduces a new mode, gallery mode and grid mode
// along with allowing multiple media keys.

// The old layout has a set of image keys it used
// This will both be rolled into a new array
export interface DeprecatedLayoutConfig {
  mediaKey?: string; // key for media, like 'examples'
  imageKey?: string; // legacy key for images, like 'examples.'  renamed to mediaKey.
}
export const convertMediaPanelConfigToLayoutV2 = (
  config: MediaBrowserPanelConfig & DeprecatedLayoutConfig
): MediaBrowserPanelConfig => {
  const oldKey = config.mediaKey ?? config.imageKey;
  if (oldKey && config.mediaKeys == null) {
    const newConfig = _.clone(config);
    newConfig.mediaKeys = [oldKey];

    return newConfig;
  }

  return config;
};

export const getSingletonValue = (
  run: {
    _wandb: string;
  },
  type: string,
  key: string
) => {
  return _.get(run._wandb, [type, key, 'value']);
};

export interface ClassLabels {
  [key: number]: string;
}

export interface ClassLabelNode {
  key: string;
  type: string;
  value: ClassLabels;
}

export type ClassLabelMap = {
  [media: string]: {
    [typeKey: string]: ClassLabelNode;
  };
};

// Gets all class labels from a set of runs
// that are attached to any of the given
// mediaKeys of the WANDB_KEY type
//
// Returns the class labels into a merged object
// with a hierarchy of {mediaKey: {subKey: classLabels}}
export function classLabelMap(
  runs: RunWithHistoryAndMediaWandb[],
  mediaKeys: string[],
  WANDB_KEY: string
): ClassLabelMap {
  return runs
    .map(r => {
      const labelMap = r._wandb[WANDB_KEY] ?? {};
      return Object.keys(labelMap)
        .map(k => {
          const [mediaKey, subKey] = k.split(WANDB_DELIMETER);
          if (_.includes(mediaKeys, mediaKey)) {
            const v = labelMap[k];
            return {subKey, mediaKey, value: v};
          } else {
            return null;
          }
        })
        .filter(v => v != null) as Array<{
        subKey: string;
        mediaKey: string;
        value: ClassLabelNode;
      }>;
    })
    .reduce((acc, val) => acc.concat(val), [])
    .reduce(
      (acc, v) => {
        if (acc[v.mediaKey] == null) {
          acc[v.mediaKey] = {};
        }
        acc[v.mediaKey][v.subKey] = v.value;

        return acc;
      },
      {} as {
        [mediaKey: string]: {
          [maskKey: string]: ClassLabelNode;
        };
      }
    );
}

// Right now this is just a wrapper, but
// use this as a single code path for segmentation colors
// for future flexibiltiy
export const segmentationMaskColor = (id: number) => {
  return colorNRGB(id, ROBIN16);
};

export function useNaturalDimensions(): [
  {width: number; height: number} | null,
  MutableRefObject<HTMLImageElement | null>
] {
  const [naturalWidth, setNaturalWidth] = useState<number | null>(null);
  const [naturalHeight, setNaturalHeight] = useState<number | null>(null);
  const naturalDimensions = useMemo(() => {
    if (naturalWidth == null || naturalHeight == null) {
      return null;
    }
    return {width: naturalWidth, height: naturalHeight};
  }, [naturalWidth, naturalHeight]);

  const imgRef = useRef<HTMLImageElement | null>(null);

  useLayoutEffect(() => {
    const imgEl = imgRef.current;
    if (imgEl == null) {
      return;
    }
    imgEl.onload = () => {
      setNaturalWidth(imgEl.naturalWidth);
      setNaturalHeight(imgEl.naturalHeight);
    };
  }, []);

  return [naturalDimensions, imgRef];
}
