import {RunsDataQuery} from '../../../containers/RunsDataLoader';
import * as Filter from '../../../util/filters';
import {sortToOrderString} from '../../../util/queryts';
import {SingleQuery} from './../../../util/queryTypes';

export function queryVars(
  runsDataQuery: Exclude<RunsDataQuery, 'queries'>,
  query: SingleQuery,
  nBuckets: number
) {
  const filters = JSON.stringify(Filter.toMongo(query.filters));
  const order = sortToOrderString(query.sort);

  let limit = runsDataQuery.page?.size || 10;
  if (Array.isArray(limit)) {
    console.error('Unexpected query limit of type array', {
      entityName: runsDataQuery.entityName,
      projectName: runsDataQuery.projectName,
    });
    limit = limit.flat(Infinity)[0] ?? 10;
  }
  return {
    bucketedHistorySpecs:
      runsDataQuery.bucketedHistorySpecs?.map(bhs =>
        JSON.stringify({...bhs, bins: nBuckets})
      ) ?? [],
    configKeys: runsDataQuery.configKeys,
    enableBasic: true,
    enableConfig: runsDataQuery.configKeys != null,
    enableSummary: runsDataQuery.summaryKeys != null,
    entityName: runsDataQuery.entityName,
    filters,
    groupKeys: [],
    groupLevel: 0,
    internalId: query.internalProjectId ?? '',
    limit: runsDataQuery.page?.size || 10,
    order,
    projectName: runsDataQuery.projectName,
    summaryKeys: runsDataQuery.summaryKeys,
  };
}
