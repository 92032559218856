import {TargetBlank} from '@wandb/weave/common/util/links';
import React from 'react';

import {apolloClient as client} from '../../../apolloClient';
import {envIsLocal} from '../../../config';
import {
  EnterpriseBillingPeriodOrgInfoQuery,
  UsageType,
  WeaveUsageInfoQuery,
} from '../../../generated/graphql';
import {getIntervalMarkers} from '../../../pages/Billing/AccountSettings/BillingTab/CurrentBillingPeriodUsageSection';
import {ENTERPRISE_BILLING_PERIOD_ORG_INFO} from '../../../pages/Billing/AccountSettings/BillingTab/CurrentBillingPeriodUsageSection.query';
import {canSeeTrialEndPage} from '../../../pages/Billing/AccountSettings/WarningBanner/ExpiryBannerContent';
import {TODAY} from '../../../pages/HistoricUsage/Meter/MeterSection';
import {WEAVE_USAGE_INFO} from '../../../pages/HistoricUsage/UsageTabContent.query';
import {TRIAL_END_PATH} from '../../../routes/paths';
import {
  BillingPeriod,
  getAccountPrivileges,
  getAccountWeaveLimit,
  getPrimarySub,
  getWeaveOverageSubBillingPeriod,
  isProPrimarySubscription,
  isTrialPlanPrimaryPlan,
} from '../../../util/accounts/pricing';
import {differenceInDays} from '../../../util/date';
import * as urls from '../../../util/urls';
import {Account, AccountType} from '../../Search/SearchNav/types';
import {ViewerOrg} from '../types';

export type WeaveApproachingLimitBannerProps = {
  organizationId: string;
  organizationName: string;
  weaveUsageBytes: number;
  weaveLimitBytes: number;
  cta: React.ReactNode;
};

export type WeaveExceededLimitBannerProps = {
  organizationId: string;
  weaveLimitBytes: number;
  cta: React.ReactNode;
};

export type WeaveTrialBannerProps = {
  organizationId: string;
  numDaysRemaining: number;
};

export type WeaveEnforcementBannerFlags = {
  weaveApproachingLimitBannerProps?: WeaveApproachingLimitBannerProps;
  weaveExceededLimitBannerProps?: WeaveExceededLimitBannerProps;
  weaveTrialBannerProps?: WeaveTrialBannerProps;
  weaveUsageBytes?: number;
  weaveLimitBytes?: number;
};

async function getWeaveUsageBytes(
  account: Account,
  billingPeriod: BillingPeriod | undefined,
  viewerOrg: ViewerOrg | undefined
): Promise<number> {
  const response = await client.query<WeaveUsageInfoQuery>({
    query: WEAVE_USAGE_INFO,
    fetchPolicy: 'network-only',
    variables: {
      orgName: account.name,
      usageType: UsageType.Weave,
      intervalMarkers: getIntervalMarkers(billingPeriod, UsageType.Weave),
    },
  });
  const usage = response.data?.organization?.usageTotal?.[0] ?? 0;

  return usage;
}

async function getHasEnterpriseOrbSubscription(
  account: Account
): Promise<boolean> {
  const response = await client.query<EnterpriseBillingPeriodOrgInfoQuery>({
    query: ENTERPRISE_BILLING_PERIOD_ORG_INFO,
    fetchPolicy: 'network-only',
    variables: {
      orgName: account.name,
    },
  });
  const hasEnterpriseOrbSubscription =
    response.data?.organization?.weaveEnterpriseSubscription != null &&
    response.data?.organization?.weaveEnterpriseSubscription
      ?.billingPeriodStart != null;
  return hasEnterpriseOrbSubscription;
}

export function ContactSalesCTA() {
  return (
    <TargetBlank
      href={urls.contactSalesWeaveTrialLimitReached()}
      className="font-semibold underline">
      Contact sales
    </TargetBlank>
  );
}

function UpgradePlanCTA() {
  return (
    <TargetBlank href={TRIAL_END_PATH} className="font-semibold underline">
      Upgrade plan
    </TargetBlank>
  );
}

export async function getWeaveEnforcementBannerFlags(
  account: Account,
  viewerOrg?: ViewerOrg
): Promise<WeaveEnforcementBannerFlags> {
  const flags: WeaveEnforcementBannerFlags = {};
  if (
    account.accountType === AccountType.Personal ||
    envIsLocal ||
    viewerOrg == null
  ) {
    return flags;
  }

  // don't show banners if user is on a Pro plan or Reverse Trial
  const primarySub = getPrimarySub(viewerOrg);
  if (
    primarySub != null &&
    (isProPrimarySubscription(primarySub) ||
      isTrialPlanPrimaryPlan(primarySub.plan))
  ) {
    return flags;
  }

  const [privileges, hasEnterpriseOrbSubscription, weaveLimits] =
    await Promise.all([
      getAccountPrivileges(account),
      getHasEnterpriseOrbSubscription(account),
      getAccountWeaveLimit(account),
    ]);
  const billingPeriod = getWeaveOverageSubBillingPeriod(privileges, viewerOrg);
  const weaveUsageBytes = await getWeaveUsageBytes(
    account,
    billingPeriod,
    viewerOrg
  );
  const weaveLimitBytes = weaveLimits?.weaveLimitBytes;
  // This plan doesn't have a limit on Weave usage
  if (weaveLimitBytes == null) {
    return flags;
  }
  flags.weaveUsageBytes = weaveUsageBytes;
  flags.weaveLimitBytes = weaveLimitBytes;
  const cta =
    primarySub && canSeeTrialEndPage(primarySub) ? (
      <UpgradePlanCTA />
    ) : (
      <ContactSalesCTA />
    );

  if (
    weaveUsageBytes >= weaveLimitBytes * 0.9 &&
    weaveUsageBytes < weaveLimitBytes
  ) {
    flags.weaveApproachingLimitBannerProps = {
      organizationId: account.id ?? '',
      organizationName: account.name ?? '',
      weaveUsageBytes,
      weaveLimitBytes,
      cta,
    };
  } else if (
    weaveUsageBytes >= weaveLimitBytes &&
    !(account.isEnterprise === true)
  ) {
    flags.weaveExceededLimitBannerProps = {
      organizationId: account.id ?? '',
      weaveLimitBytes,
      cta,
    };
  } else if (account?.isEnterprise === true && !hasEnterpriseOrbSubscription) {
    const weaveTrialEndDate = privileges.weaveLimitDateInterval.end;
    const numDaysRemaining = differenceInDays(weaveTrialEndDate, TODAY);
    // Warn that the trial will expire soon
    flags.weaveTrialBannerProps = {
      organizationId: account.id ?? '',
      numDaysRemaining: numDaysRemaining,
    };
  }
  return flags;
}
