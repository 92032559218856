import ModifiedDropdown from '@wandb/weave/common/components/elements/ModifiedDropdown';
import {
  Button as WeaveButton,
  ButtonSizes as WeaveButtonSizes,
  ButtonVariants as WeaveButtonVariants,
  Slider as WeaveSlider,
} from '@wandb/weave/components';
import {Tailwind, TailwindContents} from '@wandb/weave/components/Tailwind';
import {uniq} from 'lodash';
import React, {memo, useContext, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Checkbox, Tab} from 'semantic-ui-react';
import {twMerge} from 'tailwind-merge';

import {DEFAULT_LINE_PLOT_MAX_GROUP_RUNS} from '../../components/WorkspaceDrawer/Settings/runLinePlots/maxRunDefaults';
import {useIsInLoadedReportContext} from '../../pages/ReportPage/LoadedReportContext';
import type {SettingType} from '../../services/analytics/workspaceSettingsEvents';
import {RunQueryContext} from '../../state/runs/context';
import DOC_URLS from '../../util/doc_urls';
import {prettyXAxisLabel} from '../../util/plotHelpers/axis';
import {
  useRampFlagFormatXAxis,
  useRampFlagFullFidelityInReports,
} from '../../util/rampFeatureFlags';
import * as RunHelpers from '../../util/runhelpers';
import {MetricsPicker} from '../elements/MetricsPicker';
import RangeInput from '../elements/RangeInput';
import {smoothingTypes} from '../elements/SmoothingConfig';
import {useKeyInfoQueryContext} from '../MultiRunWorkspace/KeyInfoQueryContext';
import {usePanelContext} from '../Panel/PanelContextProvider';
import PanelChartOptions from '../PanelChartOptions';
import {
  PanelChartModal,
  PanelChartPreview,
  PanelChartSettings,
} from '../PanelCommon';
import {usePanelActionsContext} from '../PanelEditor';
import {PanelExpressionOptions} from '../PanelExpressionOptions';
import {OutliersPanelGroupingOptions} from '../PanelGrouping/OutliersPanelGrouping';
import {PanelGroupingOptions} from '../PanelGrouping/PanelGroupingOptions';
import PanelLegend from '../PanelLegend';
import {SettingIndentedRow} from '../WorkspaceDrawer/Settings/components/SettingIndentedRow';
import {SettingLabel} from '../WorkspaceDrawer/Settings/components/SettingLabel';
import {useTrackSettingsChange} from '../WorkspaceDrawer/Settings/hooks/useTrackSettingEvent';
import {LinePlotConfigExcludeOutliers} from '../WorkspaceDrawer/Settings/runLinePlots/LinePlotConfigExcludeOutliers';
import {LinePlotPointAggregation} from '../WorkspaceDrawer/Settings/runLinePlots/LinePlotPointAggregation';
import {
  EMPTY_SMOOTHING_SETTINGS,
  LinePlotSmoothing,
} from '../WorkspaceDrawer/Settings/runLinePlots/LinePlotSmoothing';
import {getMaxRunLimitWithDefault} from '../WorkspaceDrawer/Settings/runLinePlots/utils/getCascadingMaxRuns';
import {XAxisFormat} from '../WorkspaceDrawer/Settings/runLinePlots/XAxisFormat';
import {
  DerivedPointVisualizationOption,
  isBucketingOption,
  isFullFidelityMode,
} from '../WorkspaceDrawer/Settings/types';
import {LabeledOptionBlock as LabeledOption} from './../elements/LabeledOptionBlock';
import {defaultTitle} from './common';
import {runsLinePlotConfigDefaults, X_AXIS_LABELS} from './defaults';
import {PlotTypeSelection} from './edit/PlotTypeSelection';
import {GraphWrapper} from './Graph';
import {usePanelConfigContext} from './PanelConfigContext';
import {usePanelInteractionContext} from './PanelInteractionContext';
import * as S from './PanelRunsLinePlot.styles';
import {usePanelZoom} from './PanelZoomContext';
import {useProjectIsV2} from './ProjectDetails';
import {usePanelGroupingSettings} from './RunsLinePlotContext/usePanelGroupingSettings';
import {RunsLinePlotConfig, RunsLinePlotPanelInnerProps} from './types';
import {useLegendVisibility} from './useLegendVisibility';
import {getXAxisChoices, getYAxisChoices} from './utils';
import {isBarPlot, isEmptyChart} from './utils/checkTypes';
import {usePanelZoom2} from './zoom/PanelZoomContext2';
const ABSOLUTE_MAX_GROUP_RUNS = 1000;

export const ConfigWrapperComp = (props: RunsLinePlotPanelInnerProps) => {
  const {data, keyInfo} = props;

  if (data.histories == null || keyInfo == null) {
    return <p>No Histories</p>;
  }

  return <Config {...props} keyInfo={keyInfo} />;
};

export const ConfigWrapper = memo(ConfigWrapperComp);

export type ConfigProps = Omit<RunsLinePlotPanelInnerProps, 'keyInfo'> & {
  /**
   * The `featureSupport` prop is used to conditionally show/hide certain tabs based on active support by plot mode. Sampled charts support all tabs, while bucketed charts will move toward feature parity over time. The `featureSupport` optional property allows incremental rollout of features in bucketing.
   */
  featureSupport?: {
    expressions: boolean;
    grouping: boolean;
  };
  keyInfo: NonNullable<RunsLinePlotPanelInnerProps['keyInfo']>;
};

export const Config = memo(function PanelRunsLinePlotConfig(
  props: ConfigProps
) {
  const {entityName} = useParams<{entityName?: string}>();
  const {fromV2} = useProjectIsV2({
    entityName: props.query.entityName,
    projectName: props.query.projectName,
  });
  const {config, updateConfig} = props;
  const {
    keyInfoQuery: {historyKeyInfo: keyInfo},
  } = useKeyInfoQueryContext();
  const runsContext = useContext(RunQueryContext);

  const trackPanelSettingsChange = useTrackSettingsChange();
  const {setRunOnApply} = usePanelActionsContext();

  // config settings
  const {isGrouped} = usePanelGroupingSettings();
  const {
    defaultLegendTemplate,
    excludeOutliers,
    isSingleRun,
    limit,
    pointVisualizationMethod,
    showLegend,
    smoothingType,
    smoothingWeight,
    xAxis,
    xAxisMax,
    xAxisMin,
    xLogScale,
    xAxisFormat,
    yLogScale,
  } = usePanelConfigContext();
  const {inheritedSettings} = usePanelContext();
  const isLegendShown = useLegendVisibility(isSingleRun, showLegend);
  const isBucketing = isBucketingOption(pointVisualizationMethod);
  const maxRunLimit = getMaxRunLimitWithDefault(100, isBucketing);

  const isXAxisFormatEnabled = useRampFlagFormatXAxis(props.query.entityName);

  // ramp flags
  const isInReport = useIsInLoadedReportContext();
  const allowsFullFidelityInReports = useRampFlagFullFidelityInReports(
    entityName ?? ''
  );

  const {zooming: zooming1} = usePanelInteractionContext();
  const {handleConfigZoomChange, handleUserZoomChange} = usePanelZoom();
  const {handleUserZoom, configZoom, zooming: zooming2} = usePanelZoom2();

  /**
   * Conditional fns based on what kind of plot we're in. We're moving bucketing on to the new zoom context and we plan on deprecating the old one, but for now we're going to have to support both zoom contexts
   * - Bucketing: update the configZoom object directly
   * - Non-bucketing: use the handler from the context
   */
  const updateUserZoom = isBucketing ? handleUserZoomChange : handleUserZoom;
  const updateConfigZoom = isBucketing
    ? props.updateConfig
    : handleConfigZoomChange;
  const zooming = isBucketing ? zooming2 : zooming1;
  const renderingBarPlot = isBarPlot(props, zooming);

  const keyTypes = useMemo(
    function makeKeyTypes() {
      return RunHelpers.keyTypes(keyInfo.keys);
    },
    [keyInfo]
  );

  const [xAxisChoices, yAxisChoices] = useMemo(
    function memoXAndYChoices() {
      return [
        getXAxisChoices({
          configMetrics: config.metrics ?? [],
          configXAxis: xAxis,
          keyInfo,
          keyTypes,
        }),
        getYAxisChoices({
          configMetrics: config.metrics ?? [],
          configXAxis: xAxis,
          keyInfo,
          keyTypes,
          runsContext,
        }),
      ];
    },
    [keyInfo, config.metrics, xAxis, keyTypes, runsContext]
  );

  const xAxisOptions = useMemo(() => {
    return xAxisChoices.map(k => {
      const notMonotonic =
        keyInfo.keys[k] != null && !keyInfo.keys[k].monotonic;

      let icon = 'wbic-ic-up-arrow';
      if (['_runtime', '_timestamp', '_absolute_runtime'].indexOf(k) > -1) {
        icon = 'calendar';
      } else if (notMonotonic) {
        icon = 'chart bar';
      }

      let text = X_AXIS_LABELS[k] || k;
      if (notMonotonic && !fromV2) {
        text += ' (Not Monotonically Increasing)';
      }

      return {
        icon,
        text,
        value: k,
        key: k,
      };
    });
  }, [keyInfo.keys, fromV2, xAxisChoices]);

  const usingExpressions =
    config.expressions != null &&
    config.expressions.length > 0 &&
    !(config.expressions.length === 1 && config.expressions[0] === '');

  const settingsToTrack = useRef<Record<string, any>>({});

  const dataTab = (
    <Tab.Pane as="div" className="form-grid">
      {renderingBarPlot && (
        <p className="hint-text">
          Showing a bar chart instead of a line chart because all logged values
          are length one.
        </p>
      )}
      <LabeledOption
        label="X"
        helpText="Use step, time or any variable logged with wandb.log as xaxis."
        docUrl={DOC_URLS.compareMetrics + '#x-axis'}
        option={
          <S.OptionContainer>
            <div data-test="x-axis-select" style={{display: 'contents'}}>
              <ModifiedDropdown
                allowRegexSearch
                lazyLoad
                options={xAxisOptions}
                placeholder="X Axis"
                search
                selection
                style={{flexGrow: 1}}
                value={xAxis}
                onChange={(e, {value}) => {
                  const newConfigZoom: Partial<RunsLinePlotConfig> = {
                    xAxisMin: undefined,
                    xAxisMax: undefined,
                  };
                  const xAxisFormat =
                    value !== config.xAxis && value === '_timestamp'
                      ? 'datetime'
                      : 'number';
                  if (!isBucketing) {
                    props.updateConfig({
                      xAxis: value as string,
                      xAxisFormat,
                    });
                  } else {
                    newConfigZoom.xAxis = value as string;
                    newConfigZoom.xAxisFormat = xAxisFormat;
                  }

                  updateConfigZoom(newConfigZoom);
                  updateUserZoom({
                    xAxisMin: undefined,
                    xAxisMax: undefined,
                  });
                }}
              />
            </div>
          </S.OptionContainer>
        }
      />
      <LabeledOption
        label="Y"
        helpText="Plot any numeric metric logged with wandb.log."
        docUrl={DOC_URLS.compareMetrics + '#y-axis-variables'}
        option={
          <MetricsPicker
            config={config}
            keyInfo={keyInfo}
            keyTypes={keyTypes}
            options={yAxisChoices}
            updateConfig={updateConfig}
          />
        }
      />
      {
        <LabeledOption
          label="X Axis"
          helpText="Fix the minimum and maximum values for the x-axis"
          docUrl={DOC_URLS.compareMetrics + '#x-range-and-y-range'}
          option={
            <div>
              {isXAxisFormatEnabled ? (
                <Tailwind>
                  <SettingIndentedRow className="ml-0">
                    <div className="ml-12">
                      <XAxisFormat
                        trackSetting={() => {}}
                        updateSetting={updateConfig}
                        xAxisFormat={xAxisFormat}
                      />
                      <div className="flex min-w-[360px] items-center">
                        <SettingLabel text="Range" className="ml-14 w-[87px]" />
                        <RangeInput
                          disabled={
                            isEmptyChart(config) ||
                            (renderingBarPlot &&
                              xAxisMin == null &&
                              xAxisMax == null)
                          }
                          label="X Axis"
                          onMinChange={newVal => {
                            if (
                              (configZoom.xAxisMax ?? Infinity) <
                              (newVal ?? -Infinity)
                            ) {
                              return;
                            }
                            if (isBucketing) {
                              updateConfig({
                                xAxisMin: newVal,
                              });
                            } else {
                              handleConfigZoomChange({
                                xAxisMin: newVal,
                              });
                            }
                          }}
                          onMaxChange={newVal => {
                            if (
                              (configZoom.xAxisMin ?? -Infinity) >
                              (newVal ?? Infinity)
                            ) {
                              return;
                            }
                            if (isBucketing) {
                              updateConfig({
                                xAxisMax: newVal,
                              });
                            } else {
                              handleConfigZoomChange({
                                xAxisMax: newVal,
                              });
                            }
                          }}
                          minValue={configZoom.xAxisMin}
                          maxValue={configZoom.xAxisMax}
                          log={true}
                          logValue={xLogScale}
                          onLogChange={newVal => {
                            updateConfig({
                              xLogScale: newVal,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </SettingIndentedRow>
                </Tailwind>
              ) : (
                <RangeInput
                  disabled={
                    isEmptyChart(config) ||
                    (renderingBarPlot && xAxisMin == null && xAxisMax == null)
                  }
                  label="X Axis"
                  onMinChange={newVal => {
                    if (
                      (configZoom.xAxisMax ?? Infinity) < (newVal ?? -Infinity)
                    ) {
                      return;
                    }
                    if (isBucketing) {
                      updateConfig({
                        xAxisMin: newVal,
                      });
                    } else {
                      handleConfigZoomChange({
                        xAxisMin: newVal,
                      });
                    }
                  }}
                  onMaxChange={newVal => {
                    if (
                      (configZoom.xAxisMin ?? -Infinity) > (newVal ?? Infinity)
                    ) {
                      return;
                    }
                    if (isBucketing) {
                      updateConfig({
                        xAxisMax: newVal,
                      });
                    } else {
                      handleConfigZoomChange({
                        xAxisMax: newVal,
                      });
                    }
                  }}
                  minValue={configZoom.xAxisMin}
                  maxValue={configZoom.xAxisMax}
                  log={true}
                  logValue={xLogScale}
                  onLogChange={newVal => {
                    updateConfig({
                      xLogScale: newVal,
                    });
                  }}
                />
              )}
            </div>
          }
        />
      }

      <LabeledOption
        label="Y Axis"
        helpText="Fix the minimum and maximum values for the y-axis"
        docUrl={DOC_URLS.compareMetrics + '#x-range-and-y-range'}
        option={
          <RangeInput
            disabled={isEmptyChart(config)}
            onMinChange={newVal => {
              if ((configZoom.yAxisMax ?? Infinity) < (newVal ?? -Infinity)) {
                return;
              }
              if (isBucketing) {
                updateConfig({yAxisMin: newVal});
              } else {
                handleConfigZoomChange({yAxisMin: newVal});
              }
            }}
            onMaxChange={newVal => {
              if ((configZoom.yAxisMin ?? -Infinity) > (newVal ?? Infinity)) {
                return;
              }
              if (isBucketing) {
                updateConfig({yAxisMax: newVal});
              } else {
                handleConfigZoomChange({yAxisMax: newVal});
              }
            }}
            minValue={configZoom.yAxisMin}
            maxValue={configZoom.yAxisMax}
            label="Y Axis"
            log
            logValue={yLogScale}
            onLogChange={newVal =>
              updateConfig({
                yLogScale: newVal,
              })
            }
          />
        }
      />

      {!isSingleRun && (
        <Tailwind>
          <div
            className={
              'mt-4 grid  grid-cols-[auto_1fr_auto_auto] items-center gap-12'
            }>
            <label
              className="pr-12 text-sm font-semibold text-moon-800"
              htmlFor="max-runs">
              Max {isGrouped ? 'group' : 'run'}s to show*
            </label>
            <WeaveSlider.Root
              name="max-runs"
              onValueChange={newVal => {
                settingsToTrack.current = {
                  ...settingsToTrack.current,
                  limit: newVal[0],
                };
                setRunOnApply(() => () => {
                  trackPanelSettingsChange({
                    setting: 'limit',
                    settingValue: settingsToTrack.current?.limit?.toString(),
                  });
                });
                updateConfig({
                  limit: newVal[0],
                });
              }}
              step={1}
              min={1}
              max={maxRunLimit}
              value={[limit]}>
              <WeaveSlider.Track>
                <WeaveSlider.Range />
              </WeaveSlider.Track>
              <WeaveSlider.Thumb />
            </WeaveSlider.Root>
            <WeaveSlider.Display
              // TODO: think about updating the API here
              isDirty={
                config.limit != null &&
                config.limit !== inheritedSettings?.linePlot?.limit
              }
              onChange={newVal => {
                if (newVal < 1 || newVal > maxRunLimit) {
                  return;
                }

                settingsToTrack.current = {
                  ...settingsToTrack.current,
                  numberOfRuns: newVal,
                };
                setRunOnApply(() => () => {
                  trackPanelSettingsChange({
                    setting: 'numberOfRuns',
                    settingValue:
                      settingsToTrack.current?.numberOfRuns?.toString(),
                  });
                });
                updateConfig({
                  limit: newVal,
                });
              }}
              step={1}
              min={1}
              max={maxRunLimit}
              value={limit}
            />
            <WeaveButton
              onClick={() => {
                settingsToTrack.current = {
                  ...settingsToTrack.current,
                  numberOfRuns: undefined,
                };
                setRunOnApply(() => () => {
                  trackPanelSettingsChange({
                    setting: 'numberOfRuns',
                    settingValue:
                      settingsToTrack.current?.numberOfRuns?.toString(),
                  });
                });
                updateConfig({
                  limit: undefined,
                });
              }}
              size={WeaveButtonSizes.Large}
              icon="randomize-reset-reload"
              aria-label="Reset to workspace defaults"
              tooltip="Reset to workspace defaults"
              variant={WeaveButtonVariants.Ghost}
            />
          </div>
        </Tailwind>
      )}

      {!isInReport && isFullFidelityMode(pointVisualizationMethod) && (
        <TailwindContents>
          <LinePlotConfigExcludeOutliers
            excludeOutliers={excludeOutliers ?? 'include-outliers'}
            onExcludeOutliersChange={(areOutliersExcluded: boolean) => {
              settingsToTrack.current = {
                ...settingsToTrack.current,
                excludeOutliers: areOutliersExcluded
                  ? 'exclude-outliers'
                  : 'include-outliers',
              };

              setRunOnApply(() => () => {
                trackPanelSettingsChange({
                  setting: 'excludeOutliers',
                  settingValue: settingsToTrack.current?.excludeOutliers,
                });
              });
              updateConfig({
                excludeOutliers: areOutliersExcluded
                  ? 'exclude-outliers'
                  : 'include-outliers',
                // @ts-expect-error - ignoreOutliers is deprecated
                ignoreOutliers: undefined,
                showMinMaxOnHover: undefined,
              });
            }}
          />
        </TailwindContents>
      )}

      {(!isInReport || allowsFullFidelityInReports) && (
        <TailwindContents>
          <LinePlotPointAggregation
            className={twMerge('is-panel-config group mt-12')}
            clearSetting={() => {
              updateConfig({
                pointVisualizationMethod: undefined,
              });
            }}
            displayValues={{
              pointVisualizationMethod,
            }}
            onPointVisualizationMethodChange={(
              value: DerivedPointVisualizationOption
            ) => {
              settingsToTrack.current = {
                ...settingsToTrack.current,
                pointVisualizationMethod: value,
              };
              setRunOnApply(() => () => {
                trackPanelSettingsChange({
                  setting: 'pointVisualizationMethod',
                  settingValue:
                    settingsToTrack.current?.pointVisualizationMethod?.toString(),
                });
              });

              updateConfig({
                pointVisualizationMethod: value,
              });
            }}
            settingLevel="panel"
            values={{
              pointVisualizationMethod: config.pointVisualizationMethod,
            }}
          />
        </TailwindContents>
      )}
      <TailwindContents>
        <LinePlotSmoothing
          className={twMerge(
            'is-panel-config group mt-12',
            smoothingType === smoothingTypes.none ? 'mb-8' : ''
          )}
          displayValues={{
            smoothingWeight,
            smoothingType,
          }}
          settingLevel="panel"
          updateSetting={updateConfig}
          trackSetting={(setting: SettingType, value: string | undefined) => {
            trackPanelSettingsChange({
              setting,
              settingValue: value,
            });
          }}
          clearSetting={() => {
            updateConfig(EMPTY_SMOOTHING_SETTINGS);
          }}
          values={{
            smoothingWeight: config.smoothingWeight,
            smoothingType: config.smoothingType,
          }}
        />
      </TailwindContents>
      {smoothingWeight !== 0 && (isBucketing || !isGrouped) && (
        <LabeledOption
          label={'Show Original'}
          helpText="Show lines before smoothing"
          option={
            <Checkbox
              toggle
              checked={
                config.showOriginalAfterSmoothing ??
                runsLinePlotConfigDefaults.showOriginalAfterSmoothing
              }
              name="aggregate"
              onChange={(e, value) =>
                updateConfig({
                  showOriginalAfterSmoothing: value.checked,
                })
              }
            />
          }
        />
      )}

      {!renderingBarPlot &&
        (config.plotType != null ||
          !isSingleRun ||
          (config.metrics != null && config.metrics.length > 1)) && (
          <div data-test="plot-type-selection">
            <PlotTypeSelection
              config={config as RunsLinePlotConfig}
              updateConfig={updateConfig}
            />
          </div>
        )}
      {!isSingleRun && (
        <Tailwind>
          <div className="mt-16 font-[15px] text-moon-500">
            *Can be edited globally in workspace settings
          </div>
        </Tailwind>
      )}
    </Tab.Pane>
  );

  const groupingTab = (
    <Tab.Pane as="div" className="form-grid" data-test="grouping-tab">
      {isBucketing ? (
        <OutliersPanelGroupingOptions
          absoluteMaxGroupRuns={ABSOLUTE_MAX_GROUP_RUNS}
          config={config}
          defaultMaxGroupRuns={DEFAULT_LINE_PLOT_MAX_GROUP_RUNS}
          disabled={isEmptyChart(config)}
          isGrouped={isGrouped}
          pageQuery={props.pageQuery}
          singleRun={isSingleRun}
          totalRuns={props.data.totalRuns}
          type="lines"
          updateConfig={updateConfig}
          usingExpressions={usingExpressions}
        />
      ) : (
        <PanelGroupingOptions
          absoluteMaxGroupRuns={ABSOLUTE_MAX_GROUP_RUNS}
          config={config}
          defaultMaxGroupRuns={DEFAULT_LINE_PLOT_MAX_GROUP_RUNS}
          disabled={isEmptyChart(config)}
          isGrouped={isGrouped}
          pageQuery={props.pageQuery}
          singleRun={isSingleRun}
          totalRuns={props.data.totalRuns}
          type="lines"
          updateConfig={updateConfig}
          usingExpressions={usingExpressions}
          windowing={config.windowing}
        />
      )}
    </Tab.Pane>
  );

  const chartTab = (
    <Tab.Pane as="div" className="form-grid">
      <PanelChartOptions
        config={config}
        defaultTitle={defaultTitle(config)}
        defaultXAxisTitle={prettyXAxisLabel(xAxis, props.lines)}
        pageQuery={props.pageQuery}
        showLegend={isLegendShown}
        type={'lines'}
        updateConfig={updateConfig}
      />
    </Tab.Pane>
  );

  const legendTab = (
    <Tab.Pane as="div" className="form-grid">
      <PanelLegend
        config={config}
        defaultLegendTemplate={defaultLegendTemplate}
        defaultTitle={defaultTitle(config)}
        defaultXAxisTitle={prettyXAxisLabel(xAxis, props.lines)}
        editableLegendSeries={props.lines.filter(l => !l.aux && l.name != null)}
        pageQuery={props.pageQuery}
        singleRun={isSingleRun}
        type={'lines'}
        updateConfig={updateConfig}
      />
    </Tab.Pane>
  );

  const expressionsTab = (
    <Tab.Pane as="div" className="form-grid">
      <PanelExpressionOptions
        availableExpressionVarNames={uniq([...xAxisChoices, ...yAxisChoices])}
        config={config}
        exampleIdentifier={config.metrics?.[0] || 'x'}
        type={'lines'}
        updateConfig={updateConfig}
      />
    </Tab.Pane>
  );

  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const settingsPanes = [
    {
      isShown: true,
      menuItem: 'Data',
      render: () => dataTab,
    },
    {
      isShown: true,
      menuItem: 'Grouping',
      render: () => groupingTab,
    },
    {
      isShown: true,
      menuItem: 'Chart',
      render: () => chartTab,
    },
    {
      isShown: true,
      menuItem: 'Legend',
      render: () => legendTab,
    },
    {
      isShown: true,
      menuItem: 'Expressions',
      render: () => expressionsTab,
    },
  ].filter(pane => pane.isShown);

  return (
    <PanelChartModal id="panel-runs-line-plot-modal">
      <PanelChartPreview>
        <GraphWrapper {...props} />
      </PanelChartPreview>
      <PanelChartSettings>
        <Tab
          activeIndex={tabActiveIndex}
          onTabChange={(e, {activeIndex}) => {
            if (typeof activeIndex === 'number') {
              setTabActiveIndex(activeIndex);
            }
          }}
          panes={settingsPanes}
          menu={{
            secondary: true,
            pointing: true,
            className: 'chart-settings-tab-menu',
          }}
        />
      </PanelChartSettings>
    </PanelChartModal>
  );
});
