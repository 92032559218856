import React, {useMemo} from 'react';

import {useDeepEqualValue} from '../../util/hooks';
import {getDefaultLegendTemplate} from '../../util/legend';
import {ErrorBoundary} from '../ErrorBoundary';
import {getLinePlotSettingsWithDefaults} from '../WorkspaceDrawer/Settings/runLinePlots/utils/getLinePlotSettings';
import {logError} from './../../services/errors/errorReporting';
import {isSingleRun as checkIfSingleRun} from './../../util/panelHelpers';
import {RunSetQuery} from './../../util/queryTypes';
import {useRampFlagCoordinateZooming} from './../../util/rampFeatureFlags';
import {PanelRunsLinePlot} from './Component';
import {PanelOutliersRunsLinePlot} from './componentOutliers/ComponentOutliers';
import {runsLinePlotConfigDefaults} from './defaults';
import {PanelConfigContextProvider} from './PanelConfigContext';
import {PanelDataContextProvider} from './PanelDataContext';
import {PanelInteractionProvider} from './PanelInteractionContext';
import {PanelTimeProvider} from './PanelTimeContext';
import {PanelZoomContextProvider} from './PanelZoomContext';
import {
  RunsLinePlotContextProvider,
  useRunsLinePlotContext,
} from './RunsLinePlotContext/RunsLinePlotContext';
import {
  PanelGroupingContextProvider,
  usePanelGroupingSettings,
} from './RunsLinePlotContext/usePanelGroupingSettings';
import {useSharedPanelZoom} from './SharedPanelZoomContext';
import {RunsLinePlotPanelProps} from './types';
import {useRegexMetrics} from './useRegexMetrics';
import {getRunSets} from './utils';
import {PanelZoomContext2Provider} from './zoom/PanelZoomContext2';

const Wrapper = (props: RunsLinePlotPanelProps & {runSets: RunSetQuery[]}) => {
  const isSingleRun = checkIfSingleRun({pageQuery: props.pageQuery});
  const allowsCoordinatedZooming = useRampFlagCoordinateZooming(
    props.pageQuery.entityName
  );

  const {isHistogram, primaryAggregation, setRenderError} =
    useRunsLinePlotContext();
  const sharedPanelZoom = useSharedPanelZoom();

  const {coordinateZooming, smoothingWeight, xAxis} =
    getLinePlotSettingsWithDefaults(props.config);

  const isFullFidelity = primaryAggregation !== 'SAMPLED' && !isHistogram;
  const {isGrouped} = usePanelGroupingSettings();

  const defaultLegendTemplate = getDefaultLegendTemplate({
    aggregateMetrics:
      props.config.aggregateMetrics ??
      runsLinePlotConfigDefaults.aggregateMetrics,
    isGrouped,
    isFullFidelity,
    isSmoothed: smoothingWeight != null && smoothingWeight !== 0,
    legendFields:
      props.config.legendFields ?? runsLinePlotConfigDefaults.legendFields,
    metrics: props.config.metrics ?? runsLinePlotConfigDefaults.metrics,
    singleRun: isSingleRun,
    type: props.config.plotType ?? runsLinePlotConfigDefaults.plotType,
    useRunsets: props.runSets != null && props.runSets.length > 1,
  });

  useRegexMetrics(
    {
      metricRegex: props.config.metricRegex,
      metrics: props.config.metrics,
      useMetricRegex: props.config.useMetricRegex,
    },
    props.updateConfig
  );

  const errorContext = useMemo(
    () => ({
      config: props.config,
      // @ts-ignore panel settings exists but the types are wrong higher up
      panelSettings: props?.panelSettings ?? {},
      query: {
        entityName: props.query.entityName,
        projectName: props.query.projectName,
      },
    }),
    // @ts-ignore
    [props.config, props?.panelSettings, props.query]
  );

  const handleBucketingError = React.useCallback(
    (error: Error, info: React.ErrorInfo) => {
      setRenderError(true);
      logError(error, {
        // @ts-ignore can just log key value pairs to sentry
        componentStack: info.componentStack,
        panelType: 'bucketed-plot',
        ...errorContext,
      });
    },
    [errorContext, setRenderError]
  );

  const configZoom = useDeepEqualValue({
    xAxisMin: props.config.xAxisMin,
    xAxisMax: props.config.xAxisMax,
    yAxisMin: props.config.yAxisMin,
    yAxisMax: props.config.yAxisMax,
  });

  /**
   * Notes on Panel Zoom Contexts:
   * There are currently two zoom contexts while we transition towards full fidelity mode.
   *
   * Previously the backend could only handle API queries in terms of _step units on the x-axis, this meant that we needed a complicated (and brittle) system to translate zoom interactions back in to _step units for custom x-axis values. This required an elaborate data wrapping layer which we could use to set bounds on the zoomed range. It was also a pain to keep from inadvertently starting an infinite fetch loop (where a zoom would trigger a data request, which would return data causing the boundary values to update, causing a new fetch, etc...)
   *
   * Updates the the backend now allow us to query the API directly in terms of whatever x-axis units we want. This means we can kill all the extra wrapping layers, the elaborate utility functions that support the boundary calcuations, and all the associated unit tests. BUT we can't move RSDQs to the new zooming layer yet because of complexities within the API itself resolving the return data.
   *
   * So for now we wrap everything in both the zoom contexts, and each interior component ends up using the functions that it needs. It gets a little messing the Graph.tsx and Config.tsx components which have to correctly target the proper context based on if they're full fidelity mode or not. This should be temporary complexity as we hope to deprecate sampling mode in the near future.
   */
  return (
    <PanelDataContextProvider>
      <PanelInteractionProvider>
        <PanelTimeProvider>
          <PanelZoomContextProvider
            xAxisKey={xAxis}
            updateConfig={props.updateConfig}
            zoomConfig={configZoom}>
            <PanelZoomContext2Provider
              allowsCoordinatedZooming={
                allowsCoordinatedZooming && (coordinateZooming ?? false)
              }
              configZoom={configZoom}
              sharedPanelZoomConfig={sharedPanelZoom}
              xAxisKey={xAxis}>
              <PanelConfigContextProvider
                config={props.config}
                data={props.data}
                defaultLegendTemplate={defaultLegendTemplate}
                isFullFidelity={isFullFidelity}
                isSingleRun={isSingleRun}>
                {isFullFidelity ? (
                  <ErrorBoundary
                    onError={handleBucketingError}
                    renderError={() => <></>}>
                    <PanelOutliersRunsLinePlot {...props} />
                  </ErrorBoundary>
                ) : (
                  <PanelRunsLinePlot {...props} />
                )}
              </PanelConfigContextProvider>
            </PanelZoomContext2Provider>
          </PanelZoomContextProvider>
        </PanelTimeProvider>
      </PanelInteractionProvider>
    </PanelDataContextProvider>
  );
};

const WrapperWithContext = (props: RunsLinePlotPanelProps) => {
  // Notes on runsets: https://www.notion.so/wandbai/Run-set-67004423b75d4e5fbab4846ffdbc4ee9?pvs=4
  // Note: even on a single run workspace a runset is constructed
  const runSets = useMemo(() => getRunSets(props.pageQuery), [props.pageQuery]);
  const {useRunsTableGroupingInPanels} = useMemo(
    () => getLinePlotSettingsWithDefaults(props.config),
    [props.config]
  );

  return (
    <PanelGroupingContextProvider
      aggregate={props.config.aggregate ?? false}
      aggregateMetrics={props.config.aggregateMetrics ?? false}
      groupAgg={props.config.groupAgg}
      groupArea={props.config.groupArea}
      groupBy={props.config.groupBy ?? ''}
      runSets={runSets}
      useRunsTableGroupingInPanels={useRunsTableGroupingInPanels}>
      <RunsLinePlotContextProvider
        config={props.config}
        updateConfig={props.updateConfig}>
        <Wrapper {...props} runSets={runSets} />
      </RunsLinePlotContextProvider>
    </PanelGroupingContextProvider>
  );
};

export default WrapperWithContext;
