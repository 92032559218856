import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {FC, memo, useMemo} from 'react';
import {
  Borders,
  GradientDefs,
  HeatmapSeries,
  HorizontalGridLines,
  LineSeries,
  LineSeriesCanvas,
  LineSeriesPoint,
  MarkSeries,
  MarkSeriesCanvas,
  MarkSeriesPoint,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis';

import {formatXAxisNonTime, formatYAxis} from '../../util/plotHelpers/axis';
import {
  getAxisStyleForFontSize,
  PlotFontSize,
} from '../../util/plotHelpers/plotFontSize';
import {getPlotMargin} from '../../util/plotHelpers/plotHelpers';
import {Line} from '../../util/plotHelpers/types';
import {truncateString} from '../../util/runhelpers';
import {usePanelConfigContext} from '../PanelRunsLinePlot/PanelConfigContext';
import {XAxisType, YAxisType} from '../WorkspaceDrawer/Settings/types';
import {HeatmapSeriesCanvas} from './HeatmapSeries';
import {getStrokeDashArray, getStrokeDetail} from './lineUtils';
import {AreaSeriesCanvas} from './ReactVisFork/AreaSeriesCanvas';
import {Domain} from './types';
import {decimateArray} from './utils';
const emptyArray: any[] = [];

const getNull = (d: MarkSeriesPoint | LineSeriesPoint) => d.y !== null;
export interface LinePlotPlotProps {
  fontSize: PlotFontSize;
  height: number;
  lines: Line[];
  monotonic?: boolean;
  svg?: boolean;
  width: number;
  xAxis: string;
  xAxisTitle?: string;
  xDomain: Domain;
  xScale: 'linear' | 'log';
  yAxis: string;
  yAxisTitle?: string;
  yDomain: Domain;
  yScale: YAxisType;
}

// https://github.com/wandb/core/pull/17377#discussion_r1344802121
const hardCodedStrokeWidth = {strokeWidth: 0.5};
const hardCodedTopPadding = {top: 10};
const hardCodedBorders = {
  left: {fill: 'url(#leftFadeGradient)', opacity: 1},
  right: {fill: 'url(#rightFadeGradient)', opacity: 1},
  bottom: {fill: 'url(#bottomFadeGradient)', opacity: 1},
  top: {fill: 'url(#topFadeGradient)', opacity: 1},
};

// This component is used twice, once to render the main layer and once to render
// the bolded layer.
const LinePlotPlotComp: FC<
  LinePlotPlotProps & {
    // Indicates that this is the bolded layer, all lines passed in will be
    // highlighted.
    highlightEverything?: boolean;
    drawing: boolean;
  }
> = ({
  drawing,
  fontSize,
  height,
  highlightEverything,
  lines,
  svg = false,
  width,
  xAxis,
  xAxisTitle,
  xDomain,
  xScale,
  yAxis,
  yAxisTitle,
  yDomain,
  yScale,
}) => {
  const {xAxisFormat, xAxisIsDatetime} = usePanelConfigContext();
  let xType: XAxisType = 'linear';
  if (xAxisIsDatetime) {
    xType = 'time';
  } else if (xScale === 'log') {
    xType = 'log';
  }

  // Swap to canvas or SVG based on rendering mode

  const [HeatmapSeriesComponent, LineSeriesComponent] = React.useMemo(
    function chooseSvgComponent() {
      return [
        svg ? HeatmapSeries : HeatmapSeriesCanvas,
        svg ? (LineSeries as any) : LineSeriesCanvas,
      ];
    },
    [svg]
  );

  const xAxisTickTotal = xType === 'log' ? 3 : 5;
  const yAxisTickTotal = yScale === 'log' ? 2 : 5;
  const horizontalGridLinesTickTotal = yScale === 'log' ? 3 : 5;

  const axisStyle = useMemo(
    function getAxisStyle() {
      return getAxisStyleForFontSize(fontSize);
    },
    [fontSize]
  );

  const plotMargin = useMemo(
    function getPlotMarginMemo() {
      return getPlotMargin({
        axisKeys: {xAxis, yAxis},
        axisDomain: {yAxis: yDomain},
        axisType: {yAxis: yScale},
        tickTotal: {yAxis: yAxisTickTotal},
        fontSize,
      });
    },
    [fontSize, xAxis, yAxis, yAxisTickTotal, yDomain, yScale]
  );

  const nanLines = React.useMemo(
    function nanLinesMemo() {
      return lines
        .filter(l => l.nanPoints && l.nanPoints.length > 0)
        .map(l => {
          return {
            ...l,
            nanPoints: decimateArray(l.nanPoints ?? [], 10),
          };
        })
        .map(function mapNanSeries(line, i) {
          const placedNan = line.nanPoints?.map(p => ({
            ...p,
            y:
              Number(p.y) === Infinity
                ? yDomain[1]
                : Number(p.y) === -Infinity
                ? yDomain[0]
                : p.y,
          }));
          return (
            <MarkSeries
              key={i}
              size={3}
              fill="transparent"
              color={line.color}
              data={placedNan}
            />
          );
        });
    },
    [lines, yDomain]
  );

  const singlePointLines = React.useMemo(
    function singlePointLinesMemo() {
      // this is required or single point lines will not render
      return lines
        .filter(({data}) => data.length > 0)
        .map((line, i) => {
          if (
            line.type === 'area' ||
            line.type === 'scatter' ||
            line.type === 'heatmap'
          ) {
            return null;
          }

          // since we're extending the line data past the bounds of the chart, we have to make sure
          // we don't accidentally render a line terminus marker outside the bounds of the chart
          let singlePointData = line.data[
            line.data.length - 1
          ] as MarkSeriesPoint;
          for (let x = line.data.length - 1; x >= 0; x--) {
            if (line.data[x].x <= xDomain[1]) {
              singlePointData = line.data[x] as MarkSeriesPoint;
              break;
            }
          }
          // add a dot mark to the end of each line
          return (
            <MarkSeries
              key={i}
              color={line.color}
              data={[singlePointData as MarkSeriesPoint]}
              getNull={d => d.y !== null}
              size={2}
            />
          );
        });
    },
    [lines, xDomain]
  );

  const renderLines = React.useMemo(
    function renderLinesMemo() {
      return lines.map(function renderLinesMap(line, i) {
        const {
          areaColor,
          areaStrokeColor,
          strokeWidth: lineStrokeWidth,
        } = getStrokeDetail(line.color, highlightEverything, line.lineWidth);
        const strokeDashArray = getStrokeDashArray(line.mark, svg);

        return line.type === 'area' ? (
          <AreaSeriesCanvas
            key={`area-canvas-series-${i}`}
            color={areaColor}
            opacity={1}
            data={line.data}
            getNull={getNull}
            stroke={areaStrokeColor}
          />
        ) : line.type === 'scatter' ? (
          <MarkSeries
            key={i}
            color={line.color}
            data={line.data as MarkSeriesPoint[]}
            getNull={getNull}
            size={2}
          />
        ) : line.type === 'heatmap' ? (
          <HeatmapSeriesComponent
            key={i}
            colorRange={['#f0f0f0', line.color]}
            data={line.data}
            size={2}
          />
        ) : line.type === 'points' || line.mark === 'points' ? (
          <MarkSeriesCanvas
            key={i}
            color={line.color}
            data={line.data as LineSeriesPoint[]}
            size={2}
          />
        ) : (
          <LineSeriesComponent
            key={i}
            color={line.color}
            data={line.data as LineSeriesPoint[]}
            getNull={getNull}
            size={2}
            /**
             * Preserving the cast to any because:
             * (a) SVG Line types take a string
             * (B) Canvas lines take an array of values
             */
            strokeDasharray={strokeDashArray as any}
            strokeStyle={'solid'}
            strokeWidth={lineStrokeWidth}
          />
        );
      });
    },
    [
      HeatmapSeriesComponent,
      highlightEverything,
      lines,
      LineSeriesComponent,
      svg,
    ]
  );

  const hasDuplicateFormattedValues = (
    min: number,
    max: number,
    formatterArg: (
      n:
        | number
        | {
            valueOf(): number;
          }
    ) => string
  ) => {
    const step = (max - min) / (xAxisTickTotal - 1);
    const ticks = Array.from(
      {length: xAxisTickTotal},
      (_, i) => min + step * i
    );
    const formattedTicks = ticks.map(t => formatterArg(t));
    const hasDuplicates =
      new Set(formattedTicks).size !== formattedTicks.length;
    return hasDuplicates;
  };
  const formatter = formatXAxisNonTime(xType, xDomain[0], xDomain[1]);
  /**
   * Notes on constructing lines with React-Vis
   * https://www.notion.so/wandbai/Visualizations-c19bf54bfcd84db48a73ebb12748b944#7bf30efeaa3a4ed29e95dfc4dc1225fa
   */
  const selectClassName = drawing ? 'select-none' : '';

  return (
    <Tailwind>
      <div className={selectClassName}>
        <XYPlot
          className="line-plot-rv"
          margin={plotMargin}
          padding={hardCodedTopPadding}
          xDomain={xDomain}
          xType={xType}
          yDomain={yDomain}
          /**
           * Motivation: pass all these other domains in. Otherwise react-vis will run through all our data a bunch of times just to compute these empty lists.
           * https://github.com/wandb/core/commit/74fa74f0c24401109f4aaf87803c38558083b5aa
           *
           * Update: These domains need to be either explicitly calculated by us or calculated by React-vis depending on the visualization. Heatmaps require a domain to know how to scale the color properties on the data between the ranges values on the HeatmapSeriesComponent.
           *
           * It's possible that right now we have an unknown number of visual bugs caused by no-op'ing these domains. However, since we don't want to regress perf while we figure them out we're leaving the aggressive optimization applied and we'll roll it back conditionally when we discover the chart configurations that require it.
           */
          colorDomain={
            // React vis will calculate the min/max of the color domain if it's not provided.
            lines.some(l => l.type === 'heatmap') ? undefined : emptyArray
          }
          radiusDomain={emptyArray}
          angleDomain={emptyArray}
          fillDomain={emptyArray}
          strokeDomain={emptyArray}
          opacityDomain={emptyArray}
          sizeDomain={emptyArray}
          yType={yScale}
          width={width}
          height={height}>
          <HorizontalGridLines
            style={hardCodedStrokeWidth}
            tickTotal={horizontalGridLinesTickTotal}
          />
          {renderLines}
          {nanLines}
          {singlePointLines}
          <GradientDefs>
            <linearGradient id="leftFadeGradient" x1="0" x2="1" y1="0" y2="0">
              <stop offset="0%" stopColor="white" stopOpacity={1} />
              <stop offset="60%" stopColor="white" stopOpacity={1} />
              <stop offset="100%" stopColor="white" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="rightFadeGradient" x1="1" x2="0" y1="0" y2="0">
              <stop offset="0%" stopColor="white" stopOpacity={1} />
              <stop offset="100%" stopColor="white" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="bottomFadeGradient" x1="0" x2="0" y1="1" y2="0">
              <stop offset="0%" stopColor="white" stopOpacity={1} />
              <stop offset="40%" stopColor="white" stopOpacity={1} />
              <stop offset="100%" stopColor="white" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="topFadeGradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%" stopColor="white" stopOpacity={1} />
              <stop offset="100%" stopColor="white" stopOpacity={0} />
            </linearGradient>
          </GradientDefs>

          <Borders className="plot-border" style={hardCodedBorders} />

          <XAxis
            // This is the legend
            title={xAxisTitle || truncateString(xAxis)}
            style={axisStyle}
            tickFormat={
              formatter &&
              hasDuplicateFormattedValues(xDomain[0], xDomain[1], formatter)
                ? undefined
                : formatter
            }
            // React vis (or maybe d3) doesn't seem to respect the ticktotal
            // well in the case of log scale, so smaller vale is safer.  See WB-4525
            tickTotal={xAxisTickTotal}
          />

          <XAxis
            // These are the little tick marks
            width={0}
            tickTotal={xAxisTickTotal}
            tickFormat={tick => ''}
          />

          <YAxis
            title={yAxisTitle}
            tickFormat={formatYAxis}
            tickTotal={yAxisTickTotal}
            style={axisStyle}
          />
        </XYPlot>
      </div>
    </Tailwind>
  );
};

export const LinePlotPlot = memo(LinePlotPlotComp);
