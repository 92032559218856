import PanelError from '@wandb/weave/common/components/elements/PanelError';
import {ID} from '@wandb/weave/common/util/id';
import {defaultLanguageBinding} from '@wandb/weave/core';
import {compact, every, forEach, isArray, isObject, max} from 'lodash';
import React, {FC, useCallback, useMemo} from 'react';

import {ErrorBoundary} from '../components/ErrorBoundary';
import {usePanelContext} from '../components/Panel/PanelContextProvider';
import {RunsLinePlotConfig} from '../components/PanelRunsLinePlot/types';
import type {VegaPanel2Config} from '../components/PanelVega2';
import * as PanelWeave from '../components/PanelWeave';
import {usePanelsSharedRefsContext} from '../components/Views/contexts/PanelsSharedRefsContext';
import {usePanelWithInheritedSettings} from '../components/WorkspaceDrawer/Settings/hooks/usePanelInheritedSettings';
import {captureError} from '../integrations';
import {useRunsData, useRunSetsQuery} from '../state/runs/hooks';
import * as FilterActions from '../state/views/filter/actions';
import * as ViewHooks from '../state/views/hooks';
import * as PanelActions from '../state/views/panel/actions';
import * as PanelViewTypes from '../state/views/panel/types';
import * as Filter from '../util/filters';
import * as Run from '../util/runs';
import {default as PanelRunsLinePlot} from './../components/PanelRunsLinePlot/ComponentWrapper';
import {InstrumentedCenteredWaveLoader as Loader} from './../components/utility/InstrumentedLoader';
import {runMediaPanelMigrations} from './media';
import {isSingleRun} from './panelHelpers';
import {BasePanelProps} from './panelProps';
import {
  Config,
  getPanelSpec,
  LayoutParameters,
  Panel,
  PanelConfig,
  PanelExportRef,
  PanelExportType,
  PanelGroup,
  PanelGroupConfig,
  PanelSpec,
  PanelType,
  transformQuery,
} from './panelTypes';

export const PanelComp = (
  props: BasePanelProps & {
    panel: Panel;
    panelExportRef?: React.MutableRefObject<PanelExportRef | undefined>;
    updateConfig(newConfig: PanelConfig): void;
  }
) => {
  const {panel, updateConfig, ...passThroughProps} = props;
  const singleRun = isSingleRun(props);

  const {
    Component: PanelComponent,
    transformConfig,
    transformConfigUpdate,
  } = getPanelSpec(panel.viewType);

  const configTransformed = useMemo(
    () => transformConfig?.(panel.config as any, {singleRun}) ?? panel.config,
    [panel.config, transformConfig, singleRun]
  );

  const transformAndUpdateConfig = useCallback(
    (newConfig: PanelConfig) => {
      const newConfigTransformed =
        transformConfigUpdate != null
          ? transformConfigUpdate(newConfig as any, {singleRun})
          : newConfig;
      updateConfig(newConfigTransformed);
    },
    [updateConfig, transformConfigUpdate, singleRun]
  );

  const {loadingState} = usePanelContext();

  /**
   * Typically we lazy load the panel components, but this creates a double loading state for full fidelity runs line plots because each panel has to mount in order to dispatch its queries (which it now owns instead of the run selector). Directly importing this component and mounting it without suspense gets rid of the redundant loading state and saves users a quick blink and some layout shift in the underlying loader animation.
   */
  if (panel.viewType === 'Run History Line Plot') {
    return (
      <PanelRunsLinePlot
        {...passThroughProps}
        config={configTransformed as any}
        updateConfig={transformAndUpdateConfig as (config: any) => void}
      />
    );
  }

  return (
    <React.Suspense
      fallback={
        <div className="loader-wrapper">
          <Loader
            name={'panel-comp-suspense-loader'}
            onComplete={loadingState?.stopTimerCallback}
            size="huge"
          />
        </div>
      }>
      <PanelComponent
        {...passThroughProps}
        // Here we know that s.transformQuery can be called with panel.config, because
        // spec.type === panel.viewType. However, there's no way to get typescript
        // to narrow a union to an invidual member, so we just cast to any.
        config={configTransformed as any}
        updateConfig={transformAndUpdateConfig as (config: any) => void}
      />
    </React.Suspense>
  );
};

type PanelCompProps = Parameters<typeof PanelComp>[0];
type PanelCompReduxProps = Omit<
  PanelCompProps,
  | 'panel'
  | 'updateConfig'
  | 'data'
  | 'loading'
  | 'runsDataQuery'
  | 'query'
  | 'pageQuery'
  | 'customRunColors'
  | 'customRunNames'
> & {
  panelRef: PanelViewTypes.Ref;
  panelExportRef?: React.MutableRefObject<PanelExportRef | undefined>;
};

type MetricName = string;
export type DefinedMetric = {
  '1': MetricName;
  '5'?: number; // 1-index of name of defined metric
  '6'?: number[]; // options
};

export type MetricsDict = {
  [key: string]: string;
};

export function mapDefinedMetricToBaseMetric(
  definedMetrics: DefinedMetric[]
): MetricsDict {
  const keyMap: MetricsDict = {};
  // Precompute a set of all metric names (O(n))
  const metricsSet = new Set(definedMetrics.map(dm => dm['1']));

  for (const dm of definedMetrics) {
    try {
      const metric = dm['1'];
      const baseMetricIndex = dm['5'];
      if (metric == null || baseMetricIndex == null) {
        continue;
      }

      const baseMetric = definedMetrics[baseMetricIndex - 1]['1'];
      const replacedMetric = metric.replace(/\\./g, '.');

      if (metric.includes('\\.') && metricsSet.has(replacedMetric)) {
        keyMap[replacedMetric] = baseMetric;
      } else {
        keyMap[metric] = baseMetric;
      }
    } catch (e) {
      console.error(
        'Error mapping defined metric to base metric',
        JSON.stringify(dm, null, 2)
      );
    }
  }
  return keyMap;
}

export const PanelCompRedux: FC<PanelCompReduxProps> = (
  props: PanelCompReduxProps
) => {
  const {panelExportRef, panelRef} = props;
  const {customRunColorsRef, customRunNamesRef, runSetRefs} =
    usePanelsSharedRefsContext();
  const {inheritedSettings, panelBankSectionConfigRef} = usePanelContext();
  const panel = usePanelWithInheritedSettings(panelRef, inheritedSettings);

  const updateConfig = ViewHooks.useViewAction(panelRef, (ref, configUpdate) =>
    PanelActions.updateConfig(ref, configUpdate, panelBankSectionConfigRef)
  );

  const customRunColors = ViewHooks.usePart(customRunColorsRef);
  const customRunNames = ViewHooks.usePartMaybe(customRunNamesRef) ?? undefined;

  const runSet = ViewHooks.usePart(runSetRefs[0]);
  const convertSelectionsToFilters = ViewHooks.useViewAction(
    runSet.filtersRef,
    FilterActions.selectionsToFilters
  );

  const query = useRunSetsQuery(runSetRefs);
  const panelQuery = React.useMemo(
    () => transformQuery(query, panel),
    [query, panel]
  );

  const runsDataQuery = useRunsData(panelQuery);

  const logError = React.useCallback(
    (error: Error) => {
      window.analytics?.track('Panel Error', {
        viewType: panel.viewType,
        errorMessage: error.message,
        errorName: error.name,
        errorStack: error.stack,
      });
      captureError(error, 'Panel ErrorBoundary error');
    },
    [panel.viewType]
  );

  return (
    <ErrorBoundary onError={logError} renderError={renderPanelError}>
      <PanelComp
        {...props}
        convertSelectionsToFilters={convertSelectionsToFilters}
        customRunColors={customRunColors}
        customRunNames={customRunNames}
        data={runsDataQuery.data}
        loading={runsDataQuery.loading}
        pageQuery={query}
        panel={panel}
        panelExportRef={panelExportRef}
        query={panelQuery}
        runsDataQuery={runsDataQuery}
        updateConfig={updateConfig}
      />
    </ErrorBoundary>
  );
};

function renderPanelError() {
  return (
    <div style={{padding: 40}}>
      <PanelError
        message={
          <div>
            Oops, something went wrong. If this keeps happening, message
            support@wandb.com with a link to this page
          </div>
        }
      />
    </div>
  );
}

export function isExportable(spec: PanelSpec<any, any, any>): boolean {
  return (
    spec.exportable != null &&
    Object.values(spec.exportable).some(v => v === true)
  );
}

export function isExportableAs(
  spec: PanelSpec<any, any, any>,
  exportType: PanelExportType
): boolean {
  return spec.exportable != null && spec.exportable[exportType] === true;
}

export const EMPTY: Config = {
  views: {},
  tabs: [],
};

export const EMPTY_SINGLE_TAB: Config = {
  views: {0: {name: 'Panels', defaults: [], config: []}},
  tabs: ['0'],
};

export function configFromJSON(
  json: any,
  defaultViewType: PanelType = 'Run History Line Plot'
): Config | null {
  if (!isObject(json.views) || !isArray(json.tabs) || json.tabs.length === 0) {
    return null;
  }
  // TODO: We should validate everything but we don't, we assume tabs
  // and views are correct.
  const parsedViews: {[id: string]: PanelGroup} = {};
  forEach(
    json.views,
    (pg, viewID) =>
      (parsedViews[viewID] = panelGroupFromJSON(pg, defaultViewType))
  );
  return {
    views: parsedViews,
    tabs: json.tabs,
  };
}

export function panelGroupFromJSON(
  json: any,
  defaultViewType: PanelType = 'Run History Line Plot'
): PanelGroup {
  // This is really cheating. It doesn't do any validation, just fixes up
  // defaultViewTypes
  json.config.forEach((panel: any) => {
    if (panel.viewType == null) {
      panel.viewType = defaultViewType;
    } else if (
      panel.viewType === 'Audio' ||
      panel.viewType === 'Image Browser' ||
      panel.viewType === 'Tables' ||
      panel.viewType === 'Images'
    ) {
      panel.viewType = 'Media Browser';
    }
    if (panel.config == null) {
      panel.config = {};
    }
  });
  // filter out legacy panels -- no more Graph panels, now the info is under the Model tab in the Run page
  let config = json.config.filter((p: any) => p.viewType !== 'Graph');
  config = config.map(panelFromJSON);
  return {...json, config};
}

export interface PanelTemplate {
  yAxis: string;
  regex: RegExp;
  key: string;
  percentage: boolean;
}

export const systemPanelTemplates: {[key: string]: PanelTemplate} = {
  'system/cpu-V2': {
    yAxis: 'Process CPU Utilization (%)',
    regex: /system\/cpu(\/l:.+)?$/,
    key: 'system/cpu-V2',
    percentage: true,
  },
  'system/cpu.cpu-V2': {
    yAxis: 'System CPU Utilization (per core) (%)',
    regex: /system\/cpu\.\d+\.cpu_percent(\/l:.+)?/,
    key: 'system/cpu.cpu-V2',
    percentage: true,
  },
  'system/cpu.ecpu-V2': {
    yAxis: 'Apple eCPU (efficiency cores) Utilization (%)',
    regex: /system\/cpu\.ecpu_percent/,
    key: 'system/cpu.ecpu-V2',
    percentage: true,
  },
  'system/cpu.ecpu_freq-V2': {
    yAxis: 'Apple eCPU (efficiency cores) Frequency (MHz)',
    regex: /system\/cpu\.ecpu_freq/,
    key: 'system/cpu.ecpu_freq-V2',
    percentage: false,
  },
  'system/cpu.pcpu-V2': {
    yAxis: 'Apple pCPU (performance cores) Utilization (%)',
    regex: /system\/cpu\.pcpu_percent/,
    key: 'system/cpu.pcpu-V2',
    percentage: true,
  },
  'system/cpu.pcpu_freq-V2': {
    yAxis: 'Apple pCPU (performance cores) Frequency (MHz)',
    regex: /system\/cpu\.pcpu_freq/,
    key: 'system/cpu.pcpu_freq-V2',
    percentage: false,
  },
  'system/cpu.avg_temp-V2': {
    yAxis: 'Average CPU Temperature (\u2103)',
    regex: /system\/cpu\.avg_temp/,
    key: 'system/cpu.avg_temp-V2',
    percentage: false,
  },
  'system/swap.used-V2': {
    yAxis: 'Swap Memory Used (Bytes)',
    regex: /system\/swap\.used(\/l:.+)?$/,
    key: 'system/swap.used-V2',
    percentage: false,
  },
  'system/swap.used_percent-V2': {
    yAxis: 'Swap Memory Used (%)',
    regex: /system\/swap\.used_percent(\/l:.+)?$/,
    key: 'system/swap.used_percent-V2',
    percentage: true,
  },
  'system/memory.used-V2': {
    yAxis: 'RAM Used (Bytes)',
    regex: /system\/memory\.used(\/l:.+)?$/,
    key: 'system/memory.used-V2',
    percentage: false,
  },
  'system/memory.used_percent-V2': {
    yAxis: 'RAM Used (%)',
    regex: /system\/memory\.used_percent(\/l:.+)?$/,
    key: 'system/memory.used_percent-V2',
    percentage: true,
  },
  'system/ane.power-V2': {
    yAxis: 'Apple Neural Engine Power (W)',
    regex: /system\/ane\.power/,
    key: 'system/ane.power-V2',
    percentage: false,
  },
  'system/cpu.power-V2': {
    yAxis: 'CPU Power (W)',
    regex: /system\/cpu\.powerWatts/,
    key: 'system/cpu.power-V2',
    percentage: false,
  },
  'system/tpu.duty_cycle-V2': {
    yAxis: 'TPU Duty Cycle (%)',
    regex: /system\/tpu\.\d+\.dutyCycle(\/l:.+)?/,
    key: 'system/tpu.duty_cycle-V2',
    percentage: true,
  },
  'system/tpu.memory_usage-V2': {
    yAxis: 'TPU Memory Usage (%)',
    regex: /system\/tpu\.\d+\.memory_?[uU]sage(\/l:.+)?$/,
    key: 'system/tpu.memory_usage-V2',
    percentage: true,
  },
  'system/tpu.memory_usage_bytes-V2': {
    yAxis: 'TPU Memory Usage (Bytes)',
    regex: /system\/tpu\.\d+\.memory_?[uU]sage_?[bB]ytes(\/l:.+)?$/,
    key: 'system/tpu.memory_usage_bytes-V2',
    percentage: false,
  },
  'system/ipu.averageBoardTemperature-V2': {
    yAxis: 'IPU Average Board Temperature (\u2103)',
    regex: /system\/ipu\.\d+\.average board temp(\/l:.+)?/,
    key: 'system/ipu.averageBoardTemperature-V2',
    percentage: false,
  },
  'system/ipu.averageDieTemperature-V2': {
    yAxis: 'IPU Average Die Temperature (\u2103)',
    regex: /system\/ipu\.\d+\.average die temp(\/l:.+)?/,
    key: 'system/ipu.averageDieTemperature-V2',
    percentage: false,
  },
  'system/ipu.clock-V2': {
    yAxis: 'IPU Clock (MHz)',
    regex: /system\/ipu\.\d+\.clock(\/l:.+)?/,
    key: 'system/ipu.clock-V2',
    percentage: false,
  },
  'system/ipu.power-V2': {
    yAxis: 'IPU Power (W)',
    regex: /system\/ipu\.\d+\.ipu power(\/l:.+)?/,
    key: 'system/ipu.power-V2',
    percentage: false,
  },
  'system/ipu.utilization-V2': {
    yAxis: 'IPU Utilization (%)',
    regex: /system\/ipu\.\d+\.ipu utilisation \(%\)(\/l:.+)?$/,
    key: 'system/ipu.utilization-V2',
    percentage: true,
  },
  'system/ipu.utilizationSession-V2': {
    yAxis: 'IPU Utilization (session) (%)',
    regex: /system\/ipu\.\d+\.ipu utilisation \(session\)(\/l:.+)?/,
    key: 'system/ipu.utilizationSession-V2',
    percentage: true,
  },
  'system/memory-V2': {
    yAxis: 'System Memory Utilization (%)',
    regex: /system\/memory_percent(\/l:.+)?$/,
    key: 'system/memory-V2',
    percentage: true,
  },
  'system/proc.memory.rssMB-V2': {
    yAxis: 'Process Memory In Use (MB)',
    regex: /system\/proc\.memory\.rssMB(\/l:.+)?/,
    key: 'system/proc.memory.rssMB-V2',
    percentage: false,
  },
  'system/proc.memory.percent-V2': {
    yAxis: 'Process Memory In Use (%)',
    regex: /system\/proc\.memory\.percent(\/l:.+)?/,
    key: 'system/proc.memory.percent-V2',
    percentage: true,
  },
  'system/proc.memory.availableMB-V2': {
    yAxis: 'Process Memory Available (MB)',
    regex: /system\/proc\.memory\.availableMB(\/l:.+)?/,
    key: 'system/proc.memory.availableMB-V2',
    percentage: false,
  },
  'system/proc.cpu.threads-V2': {
    yAxis: 'Process CPU Threads In Use',
    regex: /system\/proc\.cpu\.threads(\/l:.+)?/,
    key: 'system/proc.cpu.threads-V2',
    percentage: false,
  },
  'system/disk-V2': {
    yAxis: 'Disk Utilization (%)',
    regex: /system\/disk$/,
    key: 'system/disk-V2',
    percentage: true,
  },
  'system/disk.usage_percent-V2': {
    yAxis: 'Disk Utilization (%)',
    regex: /system\/disk\.((.)+)\.usagePercent(\/l:.+)?/,
    key: 'system/disk.usage_percent-V2',
    percentage: true,
  },
  'system/disk.usage_gb-V2': {
    yAxis: 'Disk Utilization (GB)',
    regex: /system\/disk\.((.)+)\.usageGB(\/l:.+)?/,
    key: 'system/disk.usage_gb-V2',
    percentage: false,
  },
  'system/disk.io-V2': {
    yAxis: 'Disk I/O Utilization (MB)',
    regex: /system\/disk\.(in|out)(\/l:.+)?/,
    key: 'system/disk.io-V2',
    percentage: false,
  },
  'system/network-V2': {
    yAxis: 'Network Traffic (Bytes)',
    regex: /system\/network.(recv|sent)(\/l:.+)?/,
    key: 'system/network-V2',
    percentage: false,
  },
  'system/system.power_usage_watts-V2': {
    yAxis: 'System Power Usage (W)',
    regex: /system\/system\.powerWatts(\/l:.+)?$/,
    key: 'system/system.power_usage_watts-V2',
    percentage: false,
  },
  'system/gpu.gpu-V2': {
    yAxis: 'GPU Utilization (%)',
    regex: /system\/gpu\.\d+\.gpu(\/l:.+)?/,
    key: 'system/gpu.gpu-V2',
    percentage: true,
  },
  'system/gpu.temp-V2': {
    yAxis: 'GPU Temperature (\u2103)',
    regex: /system\/gpu\.\d+\.temp(\/l:.+)?/,
    key: 'system/gpu.temp-V2',
    percentage: false,
  },
  'system/gpu.freq-V2': {
    yAxis: 'GPU Frequency (MHz)',
    regex: /system\/gpu\.\d+\.freq(\/l:.+)?/,
    key: 'system/gpu.freq-V2',
    percentage: false,
  },
  'system/gpu.memory-V2': {
    yAxis: 'GPU Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.\d+\.memory(\/l:.+)?$/,
    key: 'system/gpu.memory-V2',
    percentage: true,
  },
  'system/gpu.memory_allocated-V2': {
    yAxis: 'GPU Memory Allocated (%)',
    regex: /system\/gpu\.\d+\.memory_?[aA]llocated(\/l:.+)?$/,
    key: 'system/gpu.memory_allocated-V2',
    percentage: true,
  },
  'system/gpu.memory_allocated_bytes-V2': {
    yAxis: 'GPU Memory Allocated (Bytes)',
    regex: /system\/gpu\.\d+\.memory_?[aA]llocated_?[bB]ytes(\/l:.+)?$/,
    key: 'system/gpu.memory_allocated_bytes-V2',
    percentage: false,
  },
  'system/gpu.memory_used_bytes-V2': {
    yAxis: 'GPU Memory Used (Bytes)',
    regex: /system\/gpu\.\d+\.memory_?[uU]sed(\/l:.+)?$/,
    key: 'system/gpu.memory_used_bytes-V2',
    percentage: false,
  },
  'system/gpu.recovery_count-V2': {
    yAxis: 'GPU Recovery Count',
    regex: /system\/gpu\.\d+\.recovery_?[cC]ount(\/l:.+)?$/,
    key: 'system/gpu.recovery_count-V2',
    percentage: false,
  },
  'system/gpu.enforced_power_limit_watts-V2': {
    yAxis: 'GPU Enforced Power Limit (W)',
    regex: /system\/gpu\.\d+\.enforcedPowerLimitWatts(\/l:.+)?$/,
    key: 'system/gpu.enforced_power_limit_watts-V2',
    percentage: false,
  },
  'system/gpu.powerPercent-V2': {
    yAxis: 'GPU Power Usage (%)',
    regex: /system\/gpu\.\d+\.powerPercent(\/l:.+)?$/,
    key: 'system/gpu.powerPercent-V2',
    percentage: true,
  },
  'system/gpu.powerWatts-V2': {
    yAxis: 'GPU Power Usage (W)',
    regex: /system\/gpu\.\d+\.powerWatts(\/l:.+)?$/,
    key: 'system/gpu.powerWatts-V2',
    percentage: false,
  },
  'system/gpu.SMClockSpeed-V2': {
    yAxis: 'GPU Streaming Multiprocessor (SM) Clock Speed (MHz)',
    regex: /system\/gpu\.\d+\.smClock(\/l:.+)?$/,
    key: 'system/gpu.SMClockSpeed-V2',
    percentage: false,
  },
  'system/gpu.graphicsClockSpeed-V2': {
    yAxis: 'GPU Graphics Clock Speed (MHz)',
    regex: /system\/gpu\.\d+\.graphicsClock(\/l:.+)?$/,
    key: 'system/gpu.graphicsClockSpeed-V2',
    percentage: false,
  },
  'system/gpu.memoryClockSpeed-V2': {
    yAxis: 'GPU Memory Clock Speed (MHz)',
    regex: /system\/gpu\.\d+\.memoryClock(\/l:.+)?$/,
    key: 'system/gpu.memoryClockSpeed-V2',
    percentage: false,
  },
  'system/gpu.correctedMemoryErrors-V2': {
    yAxis: 'GPU Corrected Memory Errors',
    regex: /system\/gpu\.\d+\.correctedMemoryErrors(\/l:.+)?$/,
    key: 'system/gpu.correctedMemoryErrors-V2',
    percentage: false,
  },
  'system/gpu.uncorrectedMemoryErrors-V2': {
    yAxis: 'GPU Uncorrected Memory Errors',
    regex: /system\/gpu\.\d+\.uncorrectedMemoryErrors(\/l:.+)?$/,
    key: 'system/gpu.uncorrectedMemoryErrors-V2',
    percentage: false,
  },
  'system/gpu.encoderUtilization-V2': {
    yAxis: 'GPU Encoder Utilization (%)',
    regex: /system\/gpu\.\d+\.encoderUtilization(\/l:.+)?$/,
    key: 'system/gpu.encoderUtilization-V2',
    percentage: true,
  },
  'system/gpu.process.gpu-V2': {
    yAxis: 'Process GPU Utilization (%)',
    regex: /system\/gpu\.process\.\d+\.gpu(\/l:.+)?/,
    key: 'system/gpu.process.gpu-V2',
    percentage: true,
  },
  'system/gpu.process.temp-V2': {
    yAxis: 'Process GPU Temperature (℃)',
    regex: /system\/gpu\.process\.\d+\.temp(\/l:.+)?/,
    key: 'system/gpu.process.temp-V2',
    percentage: false,
  },
  'system/gpu.process.memory-V2': {
    yAxis: 'Process GPU Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.process\.\d+\.memory(\/l:.+)?$/,
    key: 'system/gpu.process.memory-V2',
    percentage: true,
  },
  'system/gpu.process.memory_allocated-V2': {
    yAxis: 'Process GPU Memory Allocated (%)',
    regex: /system\/gpu\.process\.\d+\.memory_?[aA]llocated(\/l:.+)?$/,
    key: 'system/gpu.process.memory_allocated-V2',
    percentage: true,
  },
  'system/gpu.process.memory_allocated_bytes-V2': {
    yAxis: 'Process GPU Memory Allocated (Bytes)',
    regex:
      /system\/gpu\.process\.\d+\.memory_?[aA]llocated_?[bB]ytes(\/l:.+)?$/,
    key: 'system/gpu.process.memory_allocated_bytes-V2',
    percentage: false,
  },
  'system/gpu.process.memory_used_bytes-V2': {
    yAxis: 'Process GPU Memory Used (Bytes)',
    regex: /system\/gpu\.process\.\d+\.memory_?[uU]sed_?[bB]ytes(\/l:.+)?$/,
    key: 'system/gpu.process.memory_used_bytes-V2',
    percentage: false,
  },
  'system/gpu.process.enforced_power_limit_watts-V2': {
    yAxis: 'Process GPU Enforced Power Limit (W)',
    regex: /system\/gpu\.process\.\d+\.enforcedPowerLimitWatts(\/l:.+)?$/,
    key: 'system/gpu.process.enforced_power_limit_watts-V2',
    percentage: false,
  },
  'system/gpu.process.powerPercent-V2': {
    yAxis: 'Process GPU Power Usage (%)',
    regex: /system\/gpu\.process\.\d+\.powerPercent(\/l:.+)?$/,
    key: 'system/gpu.process.powerPercent-V2',
    percentage: true,
  },
  'system/gpu.process.powerWatts-V2': {
    yAxis: 'Process GPU Power Usage (W)',
    regex: /system\/gpu\.process\.\d+\.powerWatts(\/l:.+)?$/,
    key: 'system/gpu.process.powerWatts-V2',
    percentage: false,
  },
  'system/trn.utilization-V2': {
    yAxis: 'Trainium Neuron Core Utilization (%)',
    regex: /system\/trn\.\d+\.neuroncore_utilization(\/l:.+)?$/,
    key: 'system/trn.utilization-V2',
    percentage: true,
  },
  'system/trn.host_total_memory_usage-V2': {
    yAxis: 'Trainium Host Memory Usage, total (Bytes)',
    regex: /system\/trn\.host_total_memory_usage(\/l:.+)?$/,
    key: 'system/trn.host_total_memory_usage-V2',
    percentage: false,
  },
  'system/trn.neuron_device_total_memory_usage-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, total (Bytes)',
    regex: /system\/trn\.neuron_device_total_memory_usage(\/l:.+)?$/,
    key: 'system/trn.neuron_device_total_memory_usage-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.application_memory-V2': {
    yAxis: 'Trainium Host Memory Usage, application memory (Bytes)',
    regex: /system\/trn\.host_memory_usage\.application_memory(\/l:.+)?$/,
    key: 'system/trn.host_memory_usage.application_memory-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.constants-V2': {
    yAxis: 'Trainium Host Memory Usage, constants (Bytes)',
    regex: /system\/trn\.host_memory_usage\.constants(\/l:.+)?$/,
    key: 'system/trn.host_memory_usage.constants-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.dma_buffers-V2': {
    yAxis: 'Trainium Host Memory Usage, DMA buffers (Bytes)',
    regex: /system\/trn\.host_memory_usage\.dma_buffers(\/l:.+)?$/,
    key: 'system/trn.host_memory_usage.dma_buffers-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.tensors-V2': {
    yAxis: 'Trainium Host Memory Usage, tensors (Bytes)',
    regex: /system\/trn\.host_memory_usage\.tensors(\/l:.+)?$/,
    key: 'system/trn.host_memory_usage.tensors-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.constants-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, constants (Bytes)',
    regex: /system\/trn\.\d+\.neuroncore_memory_usage\.constants(\/l:.+)?$/,
    key: 'system/trn.neuroncore_memory_usage.constants-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.model_code-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, model code (Bytes)',
    regex: /system\/trn\.\d+\.neuroncore_memory_usage\.model_code(\/l:.+)?$/,
    key: 'system/trn.neuroncore_memory_usage.model_code-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.model_shared_scratchpad-V2': {
    yAxis:
      'Trainium Neuron Device Memory Usage, model shared scratchpad (Bytes)',
    regex:
      /system\/trn\.\d+\.neuroncore_memory_usage\.model_shared_scratchpad(\/l:.+)?$/,
    key: 'system/trn.neuroncore_memory_usage.model_shared_scratchpad-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.runtime_memory-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, runtime_memory (Bytes)',
    regex:
      /system\/trn\.\d+\.neuroncore_memory_usage\.runtime_memory(\/l:.+)?$/,
    key: 'system/trn.neuroncore_memory_usage.runtime_memory-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.tensors-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, tensors (Bytes)',
    regex: /system\/trn\.\d+\.neuroncore_memory_usage\.tensors(\/l:.+)?$/,
    key: 'system/trn.neuroncore_memory_usage.tensors-V2',
    percentage: false,
  },
};

export function panelFromJSON(json: {
  viewType: string;
  config: any;
  query: any;
  __id__?: string;
}): Panel {
  /**
   * The primary purpose of this function is to take legacy settings for plots and update them
   */

  // Doesn't fully check all panel types and options, just does some old data
  // conversions where necessary.
  let panel = json as Panel;

  // LinePlot is an old view type now merged with Run History Line Plot
  if (json.viewType === 'LinePlot') {
    if (json.config.lines) {
      json.config.metrics = json.config.lines;
    }
    json.config.singleRun = true;
    json.viewType = 'Run History Line Plot';
  }

  const viewType = json.viewType;

  if (viewType === 'Run History Line Plot') {
    // config.metrics used to be config.key when only one metric was allowed
    if (json.config.key != null) {
      json.config.metrics = [json.config.key];
      json.config.key = undefined;
    }
    // expression used to be a string
    if (json.config.expression != null) {
      json.config.expressions = [json.config.expression];
      json.config.expression = undefined;
    }

    if (json.config.groupLine != null) {
      json.config.groupAgg = json.config.groupLine;
      json.config.groupLine = undefined;
    }

    if (json.config.overrideLineTitles != null) {
      json.config.overrideSeriesTitles = json.config.overrideLineTitles;
      json.config.overrideLineTitles = undefined;
    }

    const config = json.config as RunsLinePlotConfig;
    if (config.legendFields != null) {
      config.legendFields = config.legendFields.map(Run.fixConfigKeyString);
    }
    if (config.groupBy != null && config.groupBy !== 'None') {
      config.groupBy = Run.fixConfigKey({
        section: 'config',
        name: config.groupBy,
      }).name;
    }

    const {
      'system/gpu.powerPercent-V2': powerPercent,
      'system/gpu.powerWatts-V2': powerWatts,
      'system/gpu.process.powerPercent-V2': processPercent,
      'system/gpu.process.powerWatts-V2': processWatts,
    } = systemPanelTemplates;

    // The system metrics panels got saved with bad titles, so we patch them up
    // if they look incorrect.
    switch (config.chartTitle) {
      case powerPercent.yAxis:
        if (every(config.metrics, m => m.match(powerWatts.regex))) {
          config.chartTitle = powerWatts.yAxis;
        }
        break;
      case processPercent.yAxis:
        if (every(config.metrics, m => m.match(processWatts.regex))) {
          config.chartTitle = processWatts.yAxis;
        }
        break;
    }

    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Scatter Plot') {
    const config = json.config;
    if (config.xAxis != null) {
      config.xAxis = Run.fixConfigKeyString(config.xAxis);
    }
    if (config.yAxis != null) {
      config.yAxis = Run.fixConfigKeyString(config.yAxis);
    }
    if (config.zAxis != null) {
      config.zAxis = Run.fixConfigKeyString(config.zAxis);
    }
    if (config.minColor && config.maxColor) {
      config.customGradient = [
        {offset: 0, color: config.minColor},
        {offset: 100, color: config.maxColor},
      ];
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Parallel Coordinates Plot') {
    const config = json.config;
    if (config.dimensions != null) {
      config.dimensions = config.dimensions.map(Run.fixConfigKeyString);
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Media Browser') {
    const config = json.config;

    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config: runMediaPanelMigrations(config),
    };
  } else if (viewType === 'Bar Chart') {
    const config = json.config;

    // config.metrics used to be config.key when only one metric was allowed
    if (json.config.key) {
      json.config.metrics = [json.config.key];
      json.config.key = undefined;
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config,
    };
  } else if (viewType === 'Vega2') {
    // migrate the old query format to the new
    const config = json.config as VegaPanel2Config;
    let userQuery = config.userQuery;
    const queryFields = userQuery?.queryFields ?? [];
    let fieldSettings = config.fieldSettings ?? {};

    const projectField = queryFields.find(qf => qf.name === 'project');
    const runsField = projectField?.fields.find(qf => qf.name === 'runs');
    const runSetsField = queryFields.find(qf => qf.name === 'runSets');
    if (runsField != null && runSetsField == null) {
      /* eslint-disable no-template-curly-in-string */
      userQuery = {
        queryFields: [
          {
            name: 'runSets',
            args: [{name: 'runSets', value: '${runSets}'}],
            fields: runsField.fields,
          },
        ],
      };
      /* eslint-enable no-template-curly-in-string */
      fieldSettings = Object.fromEntries(
        Object.entries(fieldSettings).map(([key, value]) => {
          if (value.startsWith('project_runs_')) {
            return [key, 'runSets_' + value.substring(13)];
          }
          return [key, value];
        })
      );
    }
    panel = {
      ...json,
      __id__: json.__id__ ?? ID(),
      viewType,
      config: {
        ...config,
        userQuery,
        fieldSettings,
      },
    };
  }
  if (json.query != null) {
    // this type of panel query should only ever contain run filters
    json.query.filters = Filter.fixRunFilter(json.query.filters);
  }
  return panel;
}

// This a unique identifier for panels to figure out which panels are pinned
// TODO: This needs to work for all plot types when we switch to the new PanelBank
// NOTE: Key resolution rules are:
//   - if the panel has an explicit `key` property, this always takes precedence
//     (used for multi-metric default panels)
//   - if the panel is a vega panel, use its `historyFieldSettings.key` as the key
//   - if the panel has only one metric, that metric is the key (the panel is
//     presumed to be a single-metric default panel)
//   - if the panel has multiple metrics (with no explicit `key` property), it
//     has no key (it's presumed to be a custom chart)
export function getKey(panel: Panel): string | null {
  if (panel.key) {
    return panel.key;
  }

  if (panel.viewType === 'Run History Line Plot') {
    const panelMetrics = panel.config.metrics;
    return panelMetrics && panelMetrics.length === 1 ? panelMetrics[0] : null;
  }

  if (panel.viewType === 'Vega') {
    const historyFieldSettings = panel.config.historyFieldSettings;
    return historyFieldSettings != null && historyFieldSettings.key != null
      ? historyFieldSettings.key
      : null;
  }

  if (panel.viewType === 'Media Browser') {
    // Legacy key formats
    const mediaKey = (panel.config as any).mediaKey as string;
    const imageKey = (panel.config as any).imageKey as string;

    const ks =
      panel.config.mediaKeys ||
      (mediaKey && [mediaKey]) ||
      (imageKey && [imageKey]) ||
      null;

    return ks && ks.length === 1 ? ks[0] : null;
  }
  if (panel.viewType === 'Weave') {
    // Use the Weave panel's last pick op's key. This path should
    // be made more specific in the future.
    return PanelWeave.getKeyOfWeavePanel(panel);
  }

  // Handles default case and when panel view type is Bar Chart, Scatter Plot, or Parallel Coordinates Plot
  return null;
}

// This function generates a list of metrics displayed by a panel. This information is used
// in a bunch of places, but most importantly for determining whether the panel
// has data to display from the given runset, and whether the panel matches the
// workspace metric search regex.
//
// Note: this function returning [] does not prevent the panel from being displayed.
// Rather it ensures the panel is displayed. This prevents panels from vanishing
// when they are created or edited.
export function getMetrics(panel: Panel): string[] {
  if (panel.viewType === 'Run History Line Plot') {
    return panel.config.metrics || [];
  } else if (panel.viewType === 'Vega') {
    if (panel.config.historyFieldSettings != null) {
      return Object.values(panel.config.historyFieldSettings);
    }
    return [];
  } else if (panel.viewType === 'Media Browser') {
    // Legacy key formats
    const mediaKey = (panel.config as any).mediaKey as string;
    const imageKey = (panel.config as any).imageKey as string;

    const ks =
      panel.config.mediaKeys ||
      (mediaKey && [mediaKey]) ||
      (imageKey && [imageKey]) ||
      [];

    return ks;
  } else if (panel.viewType === 'Parallel Coordinates Plot') {
    return panel.config.columns != null
      ? panel.config.columns.map(c => c.accessor || '')
      : [];
  } else if (panel.viewType === 'Scatter Plot') {
    const keys = compact([
      panel.config.xAxis,
      panel.config.yAxis,
      panel.config.zAxis,
    ]);
    return keys;
  } else if (panel.viewType === 'Bar Chart') {
    return panel.config.metrics || [];
  } else if (panel.viewType === 'Weave') {
    const metrics = [];
    const summaryKey = PanelWeave.getKeyOfWeavePanel(panel);
    if (summaryKey == null) {
      return [];
    }
    metrics.push(summaryKey);
    // This adds the raw query expression as a metric, e.g. `runs.summary["table_name"]`
    if (panel.config.panel2Config?.exp) {
      metrics.push(
        defaultLanguageBinding
          .printGraph(panel.config.panel2Config.exp)
          .replace(/\s/g, '')
      );
    }
    return metrics;
  }
  return [];
}

export const PANEL_GRID_WIDTH = 12;

function panelGridFindNextPanelLoc(
  layouts: LayoutParameters[],
  gridWidth: number,
  panelWidth: number
) {
  const columnBottoms = new Array(gridWidth).fill(0);
  for (const panel of layouts) {
    const panelBottom = panel.y + panel.h;
    for (let x = panel.x; x < panel.x + panel.w; x++) {
      columnBottoms[x] = Math.max(columnBottoms[x], panelBottom);
    }
  }
  const candidates = [];
  for (let x = 0; x < gridWidth - panelWidth + 1; x++) {
    candidates.push(max(columnBottoms.slice(x, x + panelWidth)));
  }
  // argmin
  let min = candidates[0];
  let argmin = 0;
  for (let x = 1; x < candidates.length; x++) {
    if (candidates[x] < min) {
      min = candidates[x];
      argmin = x;
    }
  }
  return {x: argmin, y: min};
}

export function getPanelGridAddPanelLayout(panelConfigs: PanelGroupConfig) {
  return {
    ...panelGridFindNextPanelLoc(
      panelConfigs.map(c => c.layout),
      PANEL_GRID_WIDTH,
      6
    ),
    w: 6,
    h: 2,
  };
}
