import {
  getEntityNameFromPath,
  useCachedDataWithOrigin,
} from '../../../util/cache';
import * as QueryTypes from '../../../util/queryTypes';
import {useRampFlagCachedPanels} from '../../../util/rampFeatureFlags';
import {useDetectPanelVariant} from '../../Panel/hooks';
import {usePanelContext} from '../../Panel/PanelContextProvider';
import {useIsRenderingInPopup} from '../../Panel/SinglePanelInspectorContainer';
import {PanelPopupMode} from '../../Panel/types';
import {useBucketedData} from '../bucketedData/useBucketedData';
import {Range} from '../common';
import {RunsLinePlotConfig} from '../types';

type CachedBucketedDataProps = {
  pageQuery: QueryTypes.Query;
  runsLinePlotConfig: RunsLinePlotConfig;
  queryZoomRange: Range;
};

export const useCachedBucketedData = ({
  pageQuery,
  runsLinePlotConfig,
  queryZoomRange,
}: CachedBucketedDataProps) => {
  const {panelPopupMode} = usePanelContext();
  const isRenderingInPopup = useIsRenderingInPopup();
  const {isFullScreenPanelPage, isEmbeddedPanel} = useDetectPanelVariant();
  const isEnabledCachedPanels = useRampFlagCachedPanels(
    getEntityNameFromPath()
  );
  const isFullscreenMode =
    (isRenderingInPopup && panelPopupMode === PanelPopupMode.FULL_SCREEN) ||
    isFullScreenPanelPage ||
    isEmbeddedPanel;

  const pageQueryWithoutRefs = pageQuery.runSets?.map(runSet =>
    Object.fromEntries(
      Object.entries(runSet).filter(
        ([key]) =>
          !['groupSelectionsRef', 'filtersRef', 'ref', 'sortRef'].includes(key)
      )
    )
  );

  const {
    data: rawData1k,
    error: error1k,
    loading: loadingRaw1k,
  } = useBucketedData(
    runsLinePlotConfig,
    pageQuery,
    queryZoomRange,
    1000,
    isFullscreenMode && !isEnabledCachedPanels
  );

  const {
    data: rawData10k,
    error: error10k,
    loading: loading10k,
  } = useBucketedData(
    runsLinePlotConfig,
    pageQuery,
    queryZoomRange,
    10000,
    !isFullscreenMode
  );

  const key = {
    runsLinePlotConfig,
    pageQuery: pageQueryWithoutRefs,
    queryZoomRange,
  };

  const {data: cachedData1k, origin} = useCachedDataWithOrigin<
    typeof rawData1k
  >(
    {
      namespace: 'BucketedRunsDeltaQuery',
      key,
      dataFromServer: rawData1k,
      waitingForDataFromServer: loadingRaw1k,
    },
    {isFlagEnabled: isEnabledCachedPanels}
  );

  let data1k = rawData1k;
  let loading1k = loadingRaw1k;
  if (
    isEnabledCachedPanels &&
    origin === 'cache' &&
    cachedData1k.histories.data.length > 0
  ) {
    data1k = cachedData1k;
    loading1k = false;
  }

  if (isFullscreenMode) {
    // only use the 1k data if we're waiting for the initial hit of the 10k data to load
    const loadedData =
      rawData10k.histories.data.length < 1 ? data1k : rawData10k;
    return {
      data: loadedData,
      loading: loading10k,
      error: error10k || error1k,
    };
  }

  return {
    data: data1k,
    loading: loading1k,
    error: error1k,
  };
};
