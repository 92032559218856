import type {ChartAggOption} from './../../../util/plotHelpers/chartTypes';
import {buildTimePoint, extendPoint, type PointWithMeta} from './buildPoints';
import {HistoryRecord} from './types';
import {
  getAreaSafeYKeys,
  getSafeXKey,
  getSafeYKey,
  sortPointsByX,
  takeLastDuplicate,
} from './util';

export function parseHistory(
  history: HistoryRecord[],
  metric: string,
  xMetric: string,
  otherMetrics: string[],
  config: {
    agg: 'Avg' | 'minMax';
    startTime: {
      value: number;
      unit: 'seconds' | 'milliseconds';
    };
    usingExpressions: boolean; // we don't need to extend the point values if we're not computing any expressions
  },
  // this value has to get mutated because we need to track it at the chart level
  groupAgg?: ChartAggOption
) {
  const finitePoints: PointWithMeta[] = [];
  const nanPoints: PointWithMeta[] = [];

  /**
   * We need to extend the point with all metrics so we can compute expressions later on. This extension won't include the y-axis metric because:
   * a) if we include it we might overwrite the yMin value the way the code is written on the area line
   * b) we can't compute y-expressions anyway because we can only do that from the output of built lines
   */
  const xMetrics = otherMetrics.concat(xMetric);

  const xKey = getSafeXKey(xMetric, metric, 'last');
  if (config.agg === 'Avg') {
    const yKey = getSafeYKey(metric, undefined, groupAgg);
    for (const historyP of history) {
      if (xMetric === '_absolute_runtime') {
        buildTimePoint(historyP, config.startTime);
      }
      const point = {x: Number(historyP[xKey]), y: Number(historyP[yKey])};
      if (!isFinite(point.x)) {
        continue;
      }
      if (config.usingExpressions) {
        extendPoint(point, historyP, xMetrics, groupAgg);
      }
      if (!isFinite(point.y)) {
        nanPoints.push(point);
      } else {
        finitePoints.push(point);
      }
      if (historyP[`${metric}HasNaN`]) {
        nanPoints.push({...point, y: NaN});
      }
    }
  } else {
    const {yKey, y0Key} = getAreaSafeYKeys(metric);
    for (const historyP of history) {
      if (xMetric === '_absolute_runtime') {
        buildTimePoint(historyP, config.startTime);
      }
      const v = {
        x: Number(historyP[xKey]),
        y: Number(historyP[yKey]),
        y0: Number(historyP[y0Key]),
      };
      if (config.usingExpressions) {
        extendPoint(v, historyP, xMetrics, groupAgg);
      }

      finitePoints.push(v);
    }
  }

  /**
   * If plotting by _step the API returns results in order of _step, custom x-axis values aren't guaranteed to be in order so we need to sort them. But because _step is incremented as a history is logged, we can always rely on the highest _step value being the most recent value
   */
  const isPlottedAgainstStep = xMetric === '_step';

  return {
    finitePoints: isPlottedAgainstStep
      ? finitePoints
      : finitePoints.sort(sortPointsByX).filter(takeLastDuplicate),
    nanPoints: isPlottedAgainstStep
      ? nanPoints
      : nanPoints.sort(sortPointsByX).filter(takeLastDuplicate),
  };
}
